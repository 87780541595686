export const lang = {
	"公司介绍": "公司介绍",
	"品类介绍": "品类介绍",
	"服务能力": "服务能力",
	"需求反馈": "需求反馈",
	"合作伙伴": "合作伙伴",
	"联系我们": "联系我们",
	"全球优质供应链服务商": "全球优质供应链服务商",
	"为全球品牌和企业提供定制化产品解决方案": "为全球品牌和企业提供定制化产品解决方案",
	"华汾公司介绍": "华汾公司介绍",
	"aboutUs1": "华汾（北京）国际商贸有限公司，是一家集实业产品研发、生产、设计、全球品牌营销、进出口贸易销售为一身的国际贸易公司，并基于Web3.0去中心化的技术核心，通过全球贸易网络的数字智能化管理，为品牌和企业提供优质的互联网营销和数字化管理运营服务。",
	"依托全国航运优势资源及全国89座城市、90座机场、覆盖超过90%的城市地标⼴告大屏资源，全方位触达中国14亿人口，为品牌提升知名度。我们以商品为媒介，秉承着科技向上的理念，为全球消费者带来具有创新的、高品质的、国际文化意义的全新生活方式商品，并向世界展示中国商贸全新发展与创新的力量。": "依托全国航运优势资源及全国89座城市、90座机场、覆盖超过90%的城市地标⼴告大屏资源，全方位触达中国14亿人口，为品牌提升知名度。我们以商品为媒介，秉承着科技向上的理念，为全球消费者带来具有创新的、高品质的、国际文化意义的全新生活方式商品，并向世界展示中国商贸全新发展与创新的力量。",
	"公司旗下拥有自有品牌、国际授权品牌、代理品牌三大系列，拥有产品研发、生产、销售、物流、营销的完整产业链体系。": "公司旗下拥有自有品牌、国际授权品牌、代理品牌三大系列，拥有产品研发、生产、销售、物流、营销的完整产业链体系。",
	"我们能提供的品类": "我们能提供的品类",
	"个护清洁": "个护清洁",
	"清洁工具": "清洁工具",
	"家庭清洁": "家庭清洁",
	"衣物清洁": "衣物清洁",
	"洗发护发": "洗发护发",
	"身体护理": "身体护理",
	"纸品湿巾": "纸品湿巾",
	"口腔护理": "口腔护理",
	"驱蚊驱虫": "驱蚊驱虫",
	"食品酒饮": "食品酒饮",
	"乳饮冰品": "乳饮冰品",
	"中外名酒": "中外名酒",
	"方便速食": "方便速食",
	"粮油调味": "粮油调味",
	"咖啡": "咖啡",
	"休闲食品": "休闲食品",
	"美妆护肤": "美妆护肤",
	"面部护肤": "面部护肤",
	"香水": "香水",
	"彩妆": "彩妆",
	"家电": "家电",
	"家电配件": "家电配件",
	"厨房小电": "厨房小电",
	"清洁电器": "清洁电器",
	"个护健康": "个护健康",
	"空调": "空调",
	"冰箱": "冰箱",
	"电视": "电视",
	"厨卫大电": "厨卫大电",
	"视听影音": "视听影音",
	"家居厨具": "家居厨具",
	"火机烟具": "火机烟具",
	"餐具": "餐具",
	"烹饪厨具": "烹饪厨具",
	"水具酒具": "水具酒具",
	"生活日用": "生活日用",
	"居家布艺": "居家布艺",
	"床上用品": "床上用品",
	"汽摩生活": "汽摩生活",
	"电动车": "电动车",
	"摩托车": "摩托车",
	"农用机械": "农用机械",
	"搬运设备": "搬运设备",
	"装饰用品": "装饰用品",
	"派对用品": "派对用品",
	"母婴": "母婴",
	"奶粉": "奶粉",
	"尿布湿巾": "尿布湿巾",
	"洗护用品": "洗护用品",
	"营养辅食": "营养辅食",
	"喂养工具": "喂养工具",
	"欢迎访问 Go2Pie商城，查找商品": "欢迎访问 Go2Pie商城，查找商品",
	"我们的服务能力": "我们的服务能力",
	"贸易": "贸易",
	"自主工厂生产及国内外品牌直供": "自主工厂生产及国内外品牌直供",
	"自研国际自由贸易平台Goπ": "自研国际自由贸易平台Goπ",
	"品牌授权及合作线下门店": "品牌授权及合作线下门店",
	"国内外各大品牌经销/代理商": "国内外各大品牌经销/代理商",
	"国际线上交易支付管理系统；": "国际线上交易支付管理系统；",
	"营销": "营销",
	"整合营销推广解决方案": "整合营销推广解决方案",
	"媒体营销推广": "媒体营销推广",
	"社交平台运营": "社交平台运营",
	"电商平台运营": "电商平台运营",
	"内容创意及制作": "内容创意及制作",
	"物流配送": "物流配送",
	"顶级物流体系及航运支持": "顶级物流体系及航运支持",
	"全国物流和冷链配送": "全国物流和冷链配送",
	"国际供应及国际运输": "国际供应及国际运输",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "提供销售通路搭建、企业集采及一件代发等全方位服务",
	"需求反馈收集": "需求反馈收集",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系",
	"提交需求反馈表单": "提交需求反馈表单",
	"集团合作伙伴": "集团合作伙伴",
	"合作伙伴及品牌": "合作伙伴及品牌",
	"合作媒体": "合作媒体",
	"华汾版权所有": "华汾版权所有",



	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	"食品":"食品",


	"咖啡":"咖啡",
	"胶囊咖啡": "胶囊咖啡",
	"黑咖啡": "黑咖啡",
	"白咖啡": "白咖啡",
	"咖啡豆/粉": "咖啡豆/粉",
	"滴咖啡": "滴咖啡",
	"咖啡液": "咖啡液",
	"咖啡伴侣": "咖啡伴侣",
	"速溶咖啡": "速溶咖啡",
	"即饮咖啡": "即饮咖啡",
	"速溶黑咖啡": "速溶黑咖啡",
	"冻干咖啡": "冻干咖啡",
	"三倍的": "三倍的",

	"可饮用谷物":"可饮用谷物",
	"大米": "大米",
	"煎堆": "煎堆",
	"奶茶": "奶茶",
	"豆浆": "豆浆",
	"莲藕淀粉": "莲藕淀粉",
	"全麦谷物": "全麦谷物",
	"谷物粉": "谷物粉",
	"水果麦片": "水果麦片",
	"代餐摇床": "代餐摇床",
	"成人奶粉": "成人奶粉",
	"霍尼": "霍尼",

	"牛奶饮料和冰淇淋":"牛奶饮料和冰淇淋",
	"闪闪发光的水": "闪闪发光的水",
	"碳酸饮料": "碳酸饮料",
	"能量饮料": "能量饮料",
	"乳酸菌饮料": "乳酸菌饮料",
	"果蔬汁": "果蔬汁",
	"茶饮料": "茶饮料",
	"酸奶": "酸奶",
	"纯牛奶": "纯牛奶",
	"饮用水": "饮用水",
	"冰淇淋": "冰淇淋",

	"中外著名葡萄酒":"中外著名葡萄酒",
	"洋酒": "洋酒",
	"白酒类": "白酒类",
	"啤酒": "啤酒",
	"葡萄酒": "葡萄酒",
	"黄酒/保健酒": "黄酒/保健酒",

	"谷物和油调味品":"谷物和油调味品",
	"中国调味品": "中国调味品",
	"西餐": "西餐",
	"配菜": "配菜",
	"东南亚调味品": "东南亚调味品",
	"花生菜籽油": "花生菜籽油",
	"葵花籽油": "葵花籽油",
	"米油": "米油",
	"橄榄油": "橄榄油",
	"茶油": "茶油",
	"谷物": "谷物",
	"粉丝": "粉丝",
	"面粉": "面粉",

	"南北干货":"南北干货",
	"银耳": "银耳",
	"扇贝": "扇贝",
	"干蔬菜": "干蔬菜",
	"真菌": "真菌",
	"龙眼": "龙眼",
	"莲子": "莲子",
	"干蘑菇": "干蘑菇",
	"干海产品": "干海产品",
	"有机干货": "有机干货",

	"方便快餐":"方便快餐",
	"午餐肉": "午餐肉",
	"自热食品": "自热食品",
	"米粉": "米粉",
	"可以": "可以",
	"速溶汤": "速溶汤",
	"火腿": "火腿",
	"蜗牛米粉": "蜗牛米粉",
	"方便面": "方便面",
	"粽子": "粽子",
	"青团": "青团",

	"零食":"零食",
	"低热量零食": "低热量零食",
	"饼干和糕点": "饼干和糕点",
	"愚蠢的": "愚蠢的",
	"坚果和烤坚果": "坚果和烤坚果",
	"蜜饯水果": "蜜饯水果",
	"糖果": "糖果",
	"零食礼品包": "零食礼品包",
	"麻辣豆腐干": "麻辣豆腐干",
	"红烧鸡爪": "红烧鸡爪",
	"薯片膨化": "薯片膨化",
	"果冻布丁": "果冻布丁",
	"巧克力": "巧克力",

	"茶":"茶",
	"菊花茶": "菊花茶",
	"绿茶": "绿茶",
	"红茶": "红茶",
	"普洱茶": "普洱茶",
	"乌龙茶": "乌龙茶",
	"茉莉花茶": "茉莉花茶",
	"健康茶": "健康茶",
	"白茶": "白茶",
	"西湖龙井": "西湖龙井",
	"铁观音": "铁观音",
	"红茶": "红茶",


	"生鲜":"生鲜",


	"快速烘焙":"快速烘焙",
	"方便面":"方便面",
	"粤式早茶":"粤式早茶",
	"烘焙半成品":"烘焙半成品",
	"面条":"面条",
	"饺子蒸饺和炸饺子":"饺子蒸饺和炸饺子",
	"馄饨":"馄饨",
	"奶酪黄油":"奶酪黄油",
	"蛋糕和甜点":"蛋糕和甜点",
	"汤圆元宵":"汤圆元宵",
	"披萨":"披萨",
	"蛋挞":"蛋挞",
	"低温粽子":"低温粽子",

	"预先准备的菜肴":"预先准备的菜肴",
	"油炸食品":"油炸食品",
	"便餐":"便餐",
	"熟食":"熟食",
	"火锅配料":"火锅配料",
	"培根香肠":"培根香肠",
	"半成品菜肴":"半成品菜肴",
	"海鲜配菜":"海鲜配菜",
	"当地特产":"当地特产",
	"一个人":"一个人",
	"冷肉":"冷肉",
	"牛排":"牛排",
	"牛肉":"牛肉",
	"鸡":"鸡",
	"鸡蛋":"鸡蛋",
	"猪肉":"猪肉",
	"羊肉":"羊肉",
	"鸭子":"鸭子",

	"蔬菜":"蔬菜",
	"大豆制品":"大豆制品",
	"根状茎":"根状茎",
	"红薯":"红薯",
	"有机时令蔬菜":"有机时令蔬菜",
	"玉米":"玉米",
	"茄瓜":"茄瓜",
	"绿叶蔬菜":"绿叶蔬菜",
	"洋葱、姜、大蒜和胡椒":"洋葱、姜、大蒜和胡椒",
	"冷冻蔬菜":"冷冻蔬菜",
	"新鲜蘑菇":"新鲜蘑菇",
	"新鲜":"新鲜",
	"鲑鱼":"鲑鱼",
	"鱼":"鱼",
	"小龙虾":"小龙虾",
	"小虾":"小虾",
	"贝类":"贝类",
	"大闸蟹":"大闸蟹",
	"蟹":"蟹",
	"海参":"海参",

	"新鲜水果":"新鲜水果",
	"进口水果":"进口水果",
	"桃、李、杏":"桃、李、杏",
	"苹果":"苹果",
	"榴莲":"榴莲",
	"芒果":"芒果",
	"梨":"梨",
	"樱桃":"樱桃",
	"猕猴桃":"猕猴桃",
	"荔枝":"荔枝",
	"蓝莓":"蓝莓",
	"橙色":"橙色",
	"橙色/橘黄色":"橙色/橘黄色",
	"椰青":"椰青",
	"水果礼盒/礼券":"水果礼盒/礼券",


	"个护清洁":"个护清洁",


	"纸品湿巾":"纸品湿巾",
	"抽纸": "抽纸",
	"手帕纸": "手帕纸",
	"卷纸": "卷纸",
	"湿巾": "湿巾",
	"厨房纸巾": "厨房纸巾",

	"洗发护发":"洗发护发",
	"洗发水": "洗发水",
	"护发素": "护发素",
	"发膜": "发膜",
	"生发液": "生发液",
	"护发精油": "护发精油",
	"洗护套装": "洗护套装",

	"身体护理":"身体护理",
	"精油": "精油 ",
	"沐浴露": "沐浴露 ",
	"男士沐浴": "男士沐浴 ",
	"儿童沐浴": "儿童沐浴 ",
	"香皂": "香皂 ",
	"洗手液": "洗手液 ",
	"美体/工具": "美体/工具 ",
	"护手霜": "护手霜 ",
	"浴盐": "浴盐 ",
	"身体护理套": "身体护理套 ",
	"花露水": "花露水 ",

	"口腔护理":"口腔护理",
	"牙膏": "牙膏 ",
	"儿童口腔": "儿童口腔 ",
	"牙刷": "牙刷 ",
	"漱口水": "漱口水 ",
	"牙线/牙线棒": "牙线/牙线棒 ",
	"口喷": "口喷 ",
	"牙贴": "牙贴 ",

	"衣物清洁":"衣物清洁",
	"洗衣液": "洗衣液 ",
	"洗衣粉": "洗衣粉 ",
	"凝珠": "凝珠 ",
	"洗衣皂": "洗衣皂 ",
	"柔顺剂": "柔顺剂 ",
	"除菌液": "除菌液 ",

	"女性护理":"女性护理",
	"卫生巾": "卫生巾 ",
	"护垫": "护垫 ",
	"棉条": "棉条 ",
	"裤型卫生巾": "裤型卫生巾 ",
	"私处护理": "私处护理 ",

	"驱蚊驱虫":"驱蚊驱虫",
	"灭蚁": "灭蚁 ",
	"杀蟑": "杀蟑 ",
	"驱鼠": "驱鼠 ",
	"除满": "除满 ",
	"驱蝇": "驱蝇 ",

	"清洁工具":"清洁工具",
	"拖把": "拖把 ",
	"垃圾桶": "垃圾桶 ",
	"垃圾袋": "垃圾袋 ",
	"清洁刷具": "清洁刷具 ",
	"抹布/百洁布": "抹布/百洁布 ",
	"手套/鞋套": "手套/鞋套 ",
	"一次性清洁用品": "一次性清洁用品 ",
	"扫把": "扫把 ",
	"脸盆/水桶": "脸盆/水桶 ",

	"发型设计":"发型设计",
	"发胶": "发胶 ",
	"发蜡/泥": "发蜡/泥 ",
	"定型啫喱": "定型啫喱 ",
	"染发": "染发 ",
	"假发": "假发 ",
	"弹力素": "弹力素 ",
	"美发工具": "美发工具 ",
	"蓬蓬粉": "蓬蓬粉 ",

	"家庭清洁":"家庭清洁",
	"油污净": "油污净 ",
	"洗洁精": "洗洁精 ",
	"洁厕剂": "洁厕剂 ",
	"消毒液": "消毒液 ",
	"地板清洁剂": "地板清洁剂 ",
	"除湿干燥剂": "除湿干燥剂 ",

	"成人护理":"成人护理",
	"成人纸尿裤": "成人纸尿裤 ",
	"成人拉拉裤": "成人拉拉裤 ",
	"成人护理垫": "成人护理垫 ",
	"成人纸尿垫": "成人纸尿垫",

}