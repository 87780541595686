<template>
  <div id="HomePage">
    <div class="box">
      <nav
        class="navbar"
        :style="{ top: pullDownNavTop + 'px' }"
        style="display: black"
      >
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="header-nav-logo" @click="scrollToTop">
            <img src="@/assets/img/logo.png" alt="" />
          </div>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav navbar-right">
            <li
              class="item"
              @click="scrollTo(item.id)"
              :class="{ active: activeSection == item.id }"
              v-for="(item, index) in navList"
              :key="item.id"
            >
              <a href="javascript:;">
                {{ item.title }}
                <i class="underline"></i>
              </a>
            </li>
            <li class="lang" @click="showLang = !showLang">
              <p style="font-weight: bold">{{ currentLang.toUpperCase() }}</p>
              <img
                src="@/assets/img/down_jt_black.png"
                alt=""
                style="width: 8px; height: 8px"
              />

              <div class="lang-pop df-ac-jsb fd-c" v-show="showLang">
                <p
                  v-for="(item, index) in language"
                  @click="changeLang(item.lang, index)"
                  :class="{ active: item.lang == currentLang }"
                >
                  {{ item.lang.toUpperCase() }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <!-- 手机导航 -->
      <div class="mobile-nav" :style="{ top: pullDownNavTop + 'px' }">
        <div class="df-ac-jsb top">
          <div><img src="@/assets/img/logo.png" alt="" width="162px" /></div>
          <div v-if="!navOpen">
            <img
              src="@/assets/img/nav_more.png"
              class="icon"
              alt=""
              width="36px"
              @click="toggleNav"
            />
          </div>
          <div v-else>
            <img
              src="@/assets/img/nav_close.png"
              class="icon"
              alt=""
              width="36px"
              @click="toggleNav"
            />
          </div>
        </div>
        <ul>
          <li
            class="item"
            @click="scrollTo(item.id)"
            :class="{ active: activeSection == item.id }"
            v-for="(item, index) in navList"
            :key="item.id"
          >
            {{ item.title }}
          </li>
          <li class="" @click="showLang = !showLang" style="position: relative">
            <div class="df-ac-jc lang">
              <p style="font-weight: bold">{{ currentLang.toUpperCase() }}</p>
              <img
                src="@/assets/img/down_jt_black.png"
                alt=""
                style="width: 8px; height: 8px; margin-left: 7px"
              />
            </div>
            <div class="lang-pop df-ac-jsb fd-c" v-show="showLang">
              <p
                v-for="(item, index) in language"
                @click="changeLang(item.lang, index)"
                :class="{ active: item.lang == currentLang }"
              >
                {{ item.lang.toUpperCase() }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div class="content">
        <!-- 首页 -->
        <div class="container-fuild index">
          <div class="container wow fadeInDown">
            <div class="row">
              <div class="col-sm-12 col-md-12 df-ac-jc fd-c">
                <div class="txt1 text-center">
                  {{ $t("lang.全球优质供应链服务商") }}
                </div>
                <div class="txt2 text-center">
                  {{ $t("lang.为全球品牌和企业提供定制化产品解决方案") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 公司介绍 -->
        <div class="conatiner-fuild section about-us" id="section1">
          <div class="container wow fadeInDown df-ac-jc fd-c">
            <div class="txt1 text-center">
              {{ $t("lang.华汾公司介绍") }}
            </div>
            <img src="@/assets/img/bg2_1g.png" alt="" class="lou" />
            <div class="txt2">
              <div>
                <span>{{ $t("lang.company-profile-title1") }}</span>
                {{ $t("lang.company-profile1") }}
              </div>
              <template v-if="aboutShowToggle">
                <div>
                  <span>{{ $t("lang.company-profile-title2") }}</span>
                  {{ $t("lang.company-profile2") }}
                </div>

                <div>
                  <span>{{ $t("lang.company-profile-title3") }}</span>
                  {{ $t("lang.company-profile3") }}
                </div>

                <div class="mb20">
                  <span>{{ $t("lang.company-profile-title4") }}</span>
                  {{ $t("lang.company-profile4") }}
                </div>

                <div>
                  <span>{{ $t("lang.company-profile-title5") }}</span>
                  {{ $t("lang.company-profile5") }}
                </div>

                <div>
                  <span>{{ $t("lang.company-profile-title6") }}</span>
                  {{ $t("lang.company-profile6") }}
                </div>

                <div class="mb20">
                  <span>{{ $t("lang.company-profile-title7") }}</span>
                  {{ $t("lang.company-profile7") }}
                </div>
                <div>
                  <span>{{ $t("lang.company-profile-title8") }}</span>
                  {{ $t("lang.company-profile8") }}
                </div>
              </template>

              <div
                class="toggle df-ac-jc"
                @click="handleToggle"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
              >
                {{ aboutToggleText }}
                <img
                  :src="aboutToggleImg"
                  alt=""
                  :style="{
                    transform:
                      aboutShowToggle == true
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                  }"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 分类 -->
        <div class="conatiner-fuild section category" id="section2">
          <div class="container wow fadeInDown">
            <p class="title">{{ $t("lang.我们能提供的品类") }}</p>
            <div class="row">
              <div
                class="col-xs-6 col-sm-4 col-md-4 item df-ac fd-c"
                v-for="(item, index) in categoryList"
                :key="item.id"
                @mouseenter="showDescription(item, index)"
                @mouseleave="hideDescription(item, index)"
                @click.stop="!isMobile && openModal(item)"
              >
                <div
                  :class="{ bortop: index == categoryIndex }"
                  class="df-ac fd-c"
                  style="width: 100%; position: relative"
                >
                  <div
                    class="box"
                    :class="{ active: index == categoryIndex }"
                    :style="{
                      zIndex: index == categoryIndex ? 99 : 0,
                      position: index == categoryIndex ? 'relative' : 'static',
                    }"
                  >
                    <img :src="item.img" alt="" class="" />
                    <p class="txt1">{{ item.title }}</p>
                    <ul v-show="item.showDescription" @click="isMobile && openModal(item)">
                      <li v-for="item2 in item.second">{{ item2.title }}</li>
                      <div
                        class="more df-ac"
                      >
                        {{ $t("lang.全部") }}
                        <img
                          src="@/assets/img/small_right.png"
                          alt=""
                          style="margin: initial"
                        />
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-style df-ac-jc">
              <a
                :href="'https://www.go2pie.com?lang=' + currentLang"
                class="df-ac-jc"
                style="color: #fff"
                target="_blank"
              >
                {{ $t("lang.欢迎访问 Go2Pie商城，查找商品") }}
                <img
                  src="@/assets/img/right_icon.png"
                  alt=""
                  class="right-icon"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- 服务能力 -->
        <div class="conatiner-fuild section service" id="section3">
          <div class="container wow fadeInDown">
            <p class="title">{{ $t("lang.我们的服务能力") }}</p>
            <div class="row">
              <div class="col-xs-12 col-sm-4 col-md-4 item">
                <div class="box">
                  <img src="@/assets/img/bg4_1.png" alt="" class="" />
                  <p class="txt1">{{ $t("lang.贸易") }}</p>
                  <p class="txt2">
                    {{ $t("lang.自主工厂生产及国内外品牌直供") }}
                  </p>
                  <ul>
                    <li>
                      <p></p>
                      {{ $t("lang.自研国际自由贸易平台Goπ") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.品牌授权及合作线下门店") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.国内外各大品牌经销/代理商") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.国际线上交易支付管理系统") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 item">
                <div class="box">
                  <img src="@/assets/img/bg4_2.png" alt="" class="" />
                  <p class="txt1">{{ $t("lang.营销") }}</p>
                  <p class="txt2">{{ $t("lang.整合营销推广解决方案") }}</p>
                  <ul>
                    <li>
                      <p></p>
                      {{ $t("lang.媒体营销推广") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.社交平台运营") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.电商平台运营") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.内容创意及制作") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 item">
                <div class="box">
                  <img src="@/assets/img/bg4_3.png" alt="" class="" />
                  <p class="txt1">{{ $t("lang.物流配送") }}</p>
                  <p class="txt2">{{ $t("lang.顶级物流体系及航运支持") }}</p>
                  <ul>
                    <li>
                      <p></p>
                      {{ $t("lang.全国物流和冷链配送") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.国际供应及国际运输") }}
                    </li>
                    <li>
                      <p></p>
                      {{
                        $t(
                          "lang.提供销售通路搭建、企业集采及一件代发等全方位服务"
                        )
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 需求反馈 -->
        <div class="container-fuild section feedback" id="section4">
          <div class="container wow fadeInDown df-ac-jc fd-c">
            <p class="title">{{ $t("lang.需求反馈收集") }}</p>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <img src="@/assets/img/bg5_1.png" alt="" class="icon" />
                <p class="txt1">
                  {{
                    $t(
                      "lang.我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系"
                    )
                  }}
                </p>
                <div class="btn-style df-ac-jc">
                  <a
                    :href="
                      'https://www.go2pie.com/pages/order/wish/submit?lang=' +
                      currentLang
                    "
                    class="df-ac-jc"
                    style="color: #fff"
                    target="_blank"
                  >
                    {{ $t("lang.提交需求反馈表单") }}
                    <img
                      src="@/assets/img/right_icon.png"
                      alt=""
                      class="right-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 合作伙伴 -->
        <div class="container-fuild section cooperation" id="section5">
          <div class="container wow fadeInDown hidden-xs">
            <p class="title">{{ $t("lang.集团合作伙伴") }}</p>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.集团") }}
              </p>
              <img src="@/assets/img/bg6_1.png" alt="" class="img1" />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作伙伴及品牌") }}
              </p>
              <img src="@/assets/img/bg6_2g.png" alt="" class="img2" />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作媒体") }}
              </p>
              <img src="@/assets/img/bg6_3.png" alt="" class="img3" />
            </div>
          </div>

          <div class="container visible-xs">
            <p class="title">{{ $t("lang.集团合作伙伴") }}</p>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.集团") }}
              </p>
              <img
                src="@/assets/img/bg6_m1.png"
                alt="Responsive image"
                class="img-responsive"
              />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作伙伴及品牌") }}
              </p>
              <img
                src="@/assets/img/bg6_m2.png"
                alt="Responsive image"
                class="img-responsive"
              />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作媒体") }}
              </p>
              <img
                src="@/assets/img/bg6_m3.png"
                alt="Responsive image"
                class="img-responsive"
              />
            </div>
          </div>
        </div>

        <!-- 联系我们 -->
        <div class="container-fuild section contact-us" id="section6">
          <div class="container wow fadeInDown">
            <p class="title">{{ $t("lang.联系我们") }}</p>
            <img src="@/assets/img/bg7_bg1.png" alt="" class="bg visible-xs" />
            <div class="col-xs-12 col-sm-4 col-md-4 item df fd-c">
              <img src="@/assets/img/bg7_1.png" alt="" class="img1" />
              <p>
                9th Floor, T1 Building, Poly International Plaza, Chaoyang
                District, Beijing, China.
              </p>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 item df fd-c">
              <img src="@/assets/img/bg7_2.png" alt="" class="img1" />
              <p>0086-400-850-9883</p>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 item df fd-c">
              <img src="@/assets/img/bg7_3.png" alt="" class="img1" />
              <p>HFIT@huafenjiu.net</p>
            </div>
          </div>
        </div>

        <!-- 底部-->
        <div class="conatiner-fuild footer">
          <div class="container hidden-xs wow fadeInDown">
            <div class="txt1 df-ac fl">
              <img src="@/assets/img/icon.png" alt="" class="img1" />
              Huafen (Beijing) International Trade Co., Ltd
            </div>
            <div class="txt2 fr">
              © 2024 - 2025 {{ $t("lang.华汾版权所有") }}
            </div>
          </div>
          <div class="container visible-xs wow fadeInDown">
            <img
              src="@/assets/img/icon.png"
              alt=""
              class="img1"
              style="
                width: 22px;
                height: 32px;
                margin: auto;
                margin-top: 28px;
                margin-bottom: 12px;
              "
            />
            <div class="txt1">
              Huafen (Beijing) International Trade Co., Ltd
            </div>
            <div class="txt2">© 2024 - 2025 {{ $t("lang.华汾版权所有") }}</div>
          </div>
        </div>

        <!-- 右侧固定 -->
        <div
          class="right-fixed df-ac hidden-xs"
          @mouseenter="showFixedBtn"
          @mouseleave="hideFixedBtn"
          v-show="fixedBtnRightShow"
        >
          <div
            class="open df-ac"
            :style="{
              right: fixedBtnRight,
              width: fixedBtnWidth,
              zIndex: fixedBtnZindex,
            }"
          >
            <a
              :href="
                'https://www.go2pie.com/pages/order/wish/submit?lang=' +
                currentLang
              "
              class="df-ac-jc"
              style="color: #fff"
              target="_blank"
            >
              <img src="@/assets/img/fixed_right2.png" alt="" class="img1" />
              <p class="txt">
                {{ $t("lang.提交需求反馈表单") }}
              </p>
              <img src="@/assets/img/fixed_right3.png" alt="" class="img2" />
            </a>
          </div>
          <img src="@/assets/img/fixed_right1.png" alt="" class="img1" />
        </div>

        <div class="right-fixed visible-xs">
          <a
            :href="
              'https://www.go2pie.com/pages/order/wish/submit?lang=' +
              currentLang
            "
            class="df-ac-jc"
            style="color: #fff"
            target="_blank"
          >
            <img src="@/assets/img/fixed_right1.png" alt="" class="img1" />
          </a>
        </div>
      </div>
    </div>
    <!-- 弹窗组件 -->
    <div class="modal-wrapper" v-show="showModal">
      <div class="modal-content" :class="{ 'modal-enter': showModal }">
        <div class="close-btn" @click="closeModal">
          <img src="@/assets/img/nav_close.png" alt="" />
        </div>
        <div class="content" :class="{ df: !isMobile }">
          <div class="left">
            <img :src="modalItem.img" alt="" />
          </div>
          <div class="right">
            <div>
              <p class="txt1">{{ modalItem.title }}</p>
              <ul>
                <li v-for="(item2, index2) in modalItem.second" :key="index2">
                  <p class="txt2">{{ item2.title }}</p>
                  <span
                    v-for="(item3, index3) in item2.third"
                    :key="index3"
                    class="txt3"
                  >
                    {{
                      index3 === item2.third.length - 1 ? item3 : item3 + " / "
                    }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "HomePage",
  data() {
    return {
      showModal: false,
      modalItem: {},
      activeSection: "", // 当前激活的导航索引
      navList: [
        {
          id: "section1",
          title: this.$t("lang.公司介绍"),
        },
        {
          id: "section2",
          title: this.$t("lang.品类介绍"),
        },
        {
          id: "section3",
          title: this.$t("lang.服务能力"),
        },
        {
          id: "section4",
          title: this.$t("lang.需求反馈"),
        },
        {
          id: "section5",
          title: this.$t("lang.合作伙伴"),
        },
        {
          id: "section6",
          title: this.$t("lang.联系我们"),
        },
      ],
      language: [
        {
          lang: "es",
        },
        {
          lang: "en",
        },
      ],
      currentLang: localStorage.getItem("currentLang") || "es",
      showLang: false,
      categoryIndex: -1,
      pullDownNavTop: 15,
      fixedBtnRight: "-103px",
      fixedBtnWidth: "80px",
      fixedBtnZindex: 99,
      fixedBtnRightShow: true,
      isPc: false,
      navOpen: false,
      oldScrollTop: 0,
      aboutShowToggle: false,
      aboutToggleText: this.$t("lang.展开"),
      aboutToggleImg: require("@/assets/img/arrows.png"),
      isMobile: false,
      // 分类
      categoryList: [
        // 食品
        {
          id: "1",
          title: this.$t("lang.食品"),
          showDescription: false,
          img: require("@/assets/img/category1.png"),
          second: [
            {
              title: this.$t("lang.咖啡"),
              third: [
                this.$t("lang.胶囊咖啡"),
                this.$t("lang.黑咖啡"),
                this.$t("lang.白咖啡"),
                this.$t("lang.咖啡豆/粉"),
                this.$t("lang.滴咖啡"),
                this.$t("lang.咖啡液"),
                this.$t("lang.咖啡伴侣"),
                this.$t("lang.速溶咖啡"),
                this.$t("lang.即饮咖啡"),
                this.$t("lang.速溶黑咖啡"),
                this.$t("lang.即饮咖啡"),
                this.$t("lang.冻干咖啡"),
                this.$t("lang.三倍的"),
              ],
            },
            {
              title: this.$t("lang.可饮用谷物"),
              third: [
                this.$t("lang.大米"),
                this.$t("lang.煎堆"),
                this.$t("lang.奶茶"),
                this.$t("lang.豆浆"),
                this.$t("lang.莲藕淀粉"),
                this.$t("lang.全麦谷物"),
                this.$t("lang.谷物粉"),
                this.$t("lang.水果麦片"),
                this.$t("lang.代餐摇床"),
                this.$t("lang.成人奶粉"),
                this.$t("lang.霍尼"),
              ],
            },
            {
              title: this.$t("lang.牛奶饮料和冰淇淋"),
              third: [
                this.$t("lang.闪闪发光的水"),
                this.$t("lang.碳酸饮料"),
                this.$t("lang.能量饮料"),
                this.$t("lang.乳酸菌饮料"),
                this.$t("lang.果蔬汁"),
                this.$t("lang.茶饮料"),
                this.$t("lang.酸奶"),
                this.$t("lang.纯牛奶"),
                this.$t("lang.饮用水"),
                this.$t("lang.冰淇淋"),
              ],
            },
            {
              title: this.$t("lang.中外著名葡萄酒"),
              third: [
                this.$t("lang.洋酒"),
                this.$t("lang.白酒类"),
                this.$t("lang.啤酒"),
                this.$t("lang.葡萄酒"),
                this.$t("lang.黄酒/保健酒"),
              ],
            },
            {
              title: this.$t("lang.谷物和油调味品"),
              third: [
                this.$t("lang.中国调味品"),
                this.$t("lang.西餐"),
                this.$t("lang.配菜"),
                this.$t("lang.东南亚调味品"),
                this.$t("lang.花生菜籽油"),
                this.$t("lang.葵花籽油"),
                this.$t("lang.米油"),
                this.$t("lang.橄榄油"),
                this.$t("lang.茶油"),
                this.$t("lang.谷物"),
                this.$t("lang.粉丝"),
                this.$t("lang.面粉"),
              ],
            },
            {
              title: this.$t("lang.南北干货"),
              third: [
                this.$t("lang.银耳"),
                this.$t("lang.扇贝"),
                this.$t("lang.干蔬菜"),
                this.$t("lang.真菌"),
                this.$t("lang.龙眼"),
                this.$t("lang.莲子"),
                this.$t("lang.干蘑菇"),
                this.$t("lang.干海产品"),
                this.$t("lang.有机干货"),
              ],
            },
            {
              title: this.$t("lang.方便快餐"),
              third: [
                this.$t("lang.午餐肉"),
                this.$t("lang.自热食品"),
                this.$t("lang.米粉"),
                this.$t("lang.速溶汤"),
                this.$t("lang.火腿"),
                this.$t("lang.蜗牛米粉"),
                this.$t("lang.方便面"),
                this.$t("lang.粽子"),
                this.$t("lang.青团"),
              ],
            },
            {
              title: this.$t("lang.零食"),
              third: [
                this.$t("lang.低热量零食"),
                this.$t("lang.饼干和糕点"),
                this.$t("lang.愚蠢的"),
                this.$t("lang.坚果和烤坚果"),
                this.$t("lang.蜜饯水果"),
                this.$t("lang.糖果"),
                this.$t("lang.零食礼品包"),
                this.$t("lang.麻辣豆腐干"),
                this.$t("lang.红烧鸡爪"),
                this.$t("lang.薯片膨化"),
                this.$t("lang.果冻布丁"),
                this.$t("lang.巧克力"),
              ],
            },
            {
              title: this.$t("lang.茶"),
              third: [
                this.$t("lang.菊花茶"),
                this.$t("lang.绿茶"),
                this.$t("lang.红茶"),
                this.$t("lang.普洱茶"),
                this.$t("lang.乌龙茶"),
                this.$t("lang.茉莉花茶"),
                this.$t("lang.健康茶"),
                this.$t("lang.白茶"),
                this.$t("lang.西湖龙井"),
                this.$t("lang.铁观音"),
                this.$t("lang.红茶"),
              ],
            },
          ],
        },
        // 生鲜
        {
          id: "2",
          title: this.$t("lang.生鲜"),
          showDescription: false,
          img: require("@/assets/img/category2.png"),
          second: [
            {
              title: this.$t("lang.快速烘焙"),
              third: [
                this.$t("lang.方便面"),
                this.$t("lang.粤式早茶"),
                this.$t("lang.烘焙半成品"),
                this.$t("lang.面条"),
                this.$t("lang.饺子蒸饺和炸饺子"),
                this.$t("lang.馄饨"),
                this.$t("lang.奶酪黄油"),
                this.$t("lang.蛋糕和甜点"),
                this.$t("lang.汤圆元宵"),
                this.$t("lang.披萨"),
                this.$t("lang.蛋挞"),
                this.$t("lang.低温粽子"),
              ],
            },
            {
              title: this.$t("lang.预先准备的菜肴"),
              third: [
                this.$t("lang.油炸食品"),
                this.$t("lang.便餐"),
                this.$t("lang.熟食"),
                this.$t("lang.火锅配料"),
                this.$t("lang.培根香肠"),
                this.$t("lang.半成品菜肴"),
                this.$t("lang.海鲜配菜"),
                this.$t("lang.当地特产"),
                this.$t("lang.一个人"),
                this.$t("lang.冷肉"),
                this.$t("lang.牛排"),
                this.$t("lang.牛肉"),
                this.$t("lang.鸡"),
                this.$t("lang.鸡蛋"),
                this.$t("lang.猪肉"),
                this.$t("lang.羊肉"),
                this.$t("lang.鸭子"),
              ],
            },
            {
              title: this.$t("lang.蔬菜"),
              third: [
                this.$t("lang.大豆制品"),
                this.$t("lang.根状茎"),
                this.$t("lang.红薯"),
                this.$t("lang.有机时令蔬菜"),
                this.$t("lang.玉米"),
                this.$t("lang.茄瓜"),
                this.$t("lang.绿叶蔬菜"),
                this.$t("lang.洋葱、姜、大蒜和胡椒"),
                this.$t("lang.冷冻蔬菜"),
                this.$t("lang.新鲜蘑菇"),
              ],
            },
            {
              title: this.$t("lang.新鲜"),
              third: [
                this.$t("lang.鲑鱼"),
                this.$t("lang.鱼"),
                this.$t("lang.小龙虾"),
                this.$t("lang.小虾"),
                this.$t("lang.贝类"),
                this.$t("lang.大闸蟹"),
                this.$t("lang.蟹"),
                this.$t("lang.海参"),
              ],
            },
            {
              title: this.$t("lang.新鲜水果"),
              third: [
                this.$t("lang.进口水果"),
                this.$t("lang.桃、李、杏"),
                this.$t("lang.苹果"),
                this.$t("lang.榴莲"),
                this.$t("lang.芒果"),
                this.$t("lang.梨"),
                this.$t("lang.樱桃"),
                this.$t("lang.猕猴桃"),
                this.$t("lang.荔枝"),
                this.$t("lang.蓝莓"),
                this.$t("lang.橙色"),
                this.$t("lang.橙色/橘黄色"),
                this.$t("lang.椰青"),
                this.$t("lang.水果礼盒/礼券"),
              ],
            },
          ],
        },
        // 个护清洁
        {
          id: "3",
          title: this.$t("lang.个护清洁"),
          showDescription: false,
          img: require("@/assets/img/category3.png"),
          second: [
            {
              title: this.$t("lang.纸品湿巾"),
              third: [
                this.$t("lang.抽纸"),
                this.$t("lang.手帕纸"),
                this.$t("lang.卷纸"),
                this.$t("lang.湿巾"),
                this.$t("lang.厨房纸巾"),
              ],
            },
            {
              title: this.$t("lang.洗发护发"),
              third: [
                this.$t("lang.洗发水"),
                this.$t("lang.护发素"),
                this.$t("lang.发膜"),
                this.$t("lang.生发液"),
                this.$t("lang.护发精油"),
                this.$t("lang.洗护套装"),
              ],
            },
            {
              title: this.$t("lang.身体护理"),
              third: [
                this.$t("lang.精油"),
                this.$t("lang.沐浴露"),
                this.$t("lang.男士沐浴"),
                this.$t("lang.儿童沐浴"),
                this.$t("lang.香皂"),
                this.$t("lang.洗手液"),
                this.$t("lang.美体/工具"),
                this.$t("lang.护手霜"),
                this.$t("lang.浴盐"),
                this.$t("lang.身体护理套"),
                this.$t("lang.花露水"),
              ],
            },
            {
              title: this.$t("lang.口腔护理"),
              third: [
                this.$t("lang.牙膏"),
                this.$t("lang.儿童口腔"),
                this.$t("lang.牙刷"),
                this.$t("lang.漱口水"),
                this.$t("lang.牙线/牙线棒"),
                this.$t("lang.口喷"),
                this.$t("lang.牙贴"),
              ],
            },
            {
              title: this.$t("lang.衣物清洁"),
              third: [
                this.$t("lang.洗衣液"),
                this.$t("lang.洗衣粉"),
                this.$t("lang.凝珠"),
                this.$t("lang.洗衣皂"),
                this.$t("lang.柔顺剂"),
                this.$t("lang.除菌液"),
              ],
            },
            {
              title: this.$t("lang.女性护理"),
              third: [
                this.$t("lang.卫生巾"),
                this.$t("lang.护垫"),
                this.$t("lang.棉条"),
                this.$t("lang.裤型卫生巾"),
                this.$t("lang.私处护理"),
              ],
            },
            {
              title: this.$t("lang.驱蚊驱虫"),
              third: [
                this.$t("lang.灭蚁"),
                this.$t("lang.杀蟑"),
                this.$t("lang.驱鼠"),
                this.$t("lang.除满"),
                this.$t("lang.驱蝇"),
              ],
            },
            {
              title: this.$t("lang.清洁工具"),
              third: [
                this.$t("lang.拖把"),
                this.$t("lang.垃圾桶"),
                this.$t("lang.垃圾袋"),
                this.$t("lang.清洁刷具"),
                this.$t("lang.抹布/百洁布"),
                this.$t("lang.手套/鞋套"),
                this.$t("lang.一次性清洁用品"),
                this.$t("lang.扫把"),
                this.$t("lang.脸盆/水桶"),
              ],
            },
            {
              title: this.$t("lang.发型设计"),
              third: [
                this.$t("lang.发胶"),
                this.$t("lang.发蜡/泥"),
                this.$t("lang.定型啫喱"),
                this.$t("lang.染发"),
                this.$t("lang.假发"),
                this.$t("lang.弹力素"),
                this.$t("lang.美发工具"),
                this.$t("lang.蓬蓬粉"),
              ],
            },
            {
              title: this.$t("lang.家庭清洁"),
              third: [
                this.$t("lang.油污净"),
                this.$t("lang.洗洁精"),
                this.$t("lang.洁厕剂"),
                this.$t("lang.消毒液"),
                this.$t("lang.地板清洁剂"),
                this.$t("lang.除湿干燥剂"),
              ],
            },
            {
              title: this.$t("lang.成人护理"),
              third: [
                this.$t("lang.成人纸尿裤"),
                this.$t("lang.成人拉拉裤"),
                this.$t("lang.成人护理垫"),
                this.$t("lang.成人纸尿垫"),
              ],
            },
          ],
        },
        // 美妆护肤
        {
          id: "4",
          title: this.$t("lang.美妆护肤"),
          showDescription: false,
          img: require("@/assets/img/category4.png"),
          second: [
            {
              title: this.$t("lang.面部护肤"),
              third: [
                this.$t("lang.防晒"),
                this.$t("lang.眼霜眼部精华"),
                this.$t("lang.护肤"),
                this.$t("lang.洁面"),
                this.$t("lang.化妆水"),
                this.$t("lang.卸妆"),
                this.$t("lang.润唇膏"),
                this.$t("lang.眼膜"),
                this.$t("lang.T区护理"),
                this.$t("lang.唇膜唇部精华"),
              ],
            },
            {
              title: this.$t("lang.彩妆"),
              third: [
                this.$t("lang.隔离霜/妆前乳"),
                this.$t("lang.粉底液/膏"),
                this.$t("lang.唇彩唇蜜/唇釉"),
                this.$t("lang.气垫"),
                this.$t("lang.BB霜"),
                this.$t("lang.蜜粉/散粉"),
                this.$t("lang.眉笔/眉粉"),
                this.$t("lang.粉饼"),
                this.$t("lang.美甲"),
                this.$t("lang.眼影"),
                this.$t("lang.眼线笔/眼线液"),
                this.$t("lang.遮瑕"),
                this.$t("lang.腮红/胭脂"),
                this.$t("lang.睫毛膏/睫毛液"),
                this.$t("lang.CC霜"),
                this.$t("lang.修容/高光/阴影"),
                this.$t("lang.唇笔/唇线笔"),
                this.$t("lang.女士香水"),
                this.$t("lang.男士香水"),
              ],
            },
            {
              title: this.$t("lang.美妆工具"),
              third: [
                this.$t("lang.美甲工具"),
                this.$t("lang.化妆棉"),
                this.$t("lang.化妆刷"),
                this.$t("lang.粉扑/洗脸扑"),
                this.$t("lang.假睫毛"),
                this.$t("lang.修眉刀"),
                this.$t("lang.双眼皮贴"),
                this.$t("lang.睫毛夹"),
                this.$t("lang.美妆工具"),
                this.$t("lang.吸油纸"),
              ],
            },
          ],
        },
        // 家居厨具
        {
          id: "5",
          title: this.$t("lang.家居厨具"),
          showDescription: false,
          img: require("@/assets/img/category5.png"),
          second: [
            {
              title: this.$t("lang.生活日用"),
              third: [
                this.$t("lang.香薰/蜡烛"),
                this.$t("lang.衣架"),
                this.$t("lang.指甲刀"),
                this.$t("lang.纸巾盒"),
                this.$t("lang.雨伞雨具"),
                this.$t("lang.日杂小件"),
                this.$t("lang.梳子"),
                this.$t("lang.浴室置物架"),
                this.$t("lang.移动马桶"),
                this.$t("lang.保暖防护"),
                this.$t("lang.洗漱杯"),
                this.$t("lang.搓澡巾"),
                this.$t("lang.马桶刷"),
                this.$t("lang.净化除味"),
                this.$t("lang.化妆镜"),
              ],
            },
            {
              title: this.$t("lang.水具酒具"),
              third: [
                this.$t("lang.保温杯"),
                this.$t("lang.玻璃杯"),
                this.$t("lang.塑料杯"),
                this.$t("lang.陶瓷/马克杯"),
                this.$t("lang.保温壶"),
                this.$t("lang.运动水壶"),
                this.$t("lang.焖烧杯"),
                this.$t("lang.酒杯/酒具"),
              ],
            },
            {
              title: this.$t("lang.烹饪锅具"),
              third: [
                this.$t("lang.炒锅"),
                this.$t("lang.平底锅/煎锅"),
                this.$t("lang.汤锅"),
                this.$t("lang.蒸锅"),
                this.$t("lang.奶锅"),
                this.$t("lang.高压锅"),
                this.$t("lang.火锅"),
              ],
            },
            {
              title: this.$t("lang.餐具"),
              third: [
                this.$t("lang.碗"),
                this.$t("lang.盘/碟"),
                this.$t("lang.筷子"),
                this.$t("lang.刀/叉/勺"),
                this.$t("lang.果盘/果篮"),
                this.$t("lang.一次性用品"),
              ],
            },
            {
              title: this.$t("lang.厨房配件"),
              third: [
                this.$t("lang.上水器"),
                this.$t("lang.真空机"),
                this.$t("lang.厨房储物器皿"),
                this.$t("lang.壁挂置物架"),
                this.$t("lang.落地置物架"),
                this.$t("lang.台面置物架"),
                this.$t("lang.保鲜盒"),
                this.$t("lang.饭盒/提锅"),
                this.$t("lang.调料器皿"),
                this.$t("lang.厨房秤"),
                this.$t("lang.烘培用具"),
                this.$t("lang.厨房DIY/小工具"),
              ],
            },
            {
              title: this.$t("lang.火机烟具"),
              third: [
                this.$t("lang.煤油打火机"),
                this.$t("lang.一次性打火机"),
                this.$t("lang.火机油"),
                this.$t("lang.充电打火机"),
                this.$t("lang.烟嘴"),
                this.$t("lang.烟斗"),
                this.$t("lang.雪茄用品"),
                this.$t("lang.戒烟贴"),
                this.$t("lang.烟灰缸"),
              ],
            },
            {
              title: this.$t("lang.茶具/咖啡具"),
              third: [
                this.$t("lang.茶杯"),
                this.$t("lang.茶壶"),
                this.$t("lang.茶具配件"),
                this.$t("lang.茶盘"),
                this.$t("lang.咖啡杯"),
                this.$t("lang.旅行茶具"),
                this.$t("lang.咖啡具配件"),
              ],
            },
            {
              title: this.$t("lang.刀剪菜板"),
              third: [
                this.$t("lang.砧板"),
                this.$t("lang.菜刀"),
                this.$t("lang.瓜果刀/刨"),
                this.$t("lang.剪刀"),
                this.$t("lang.多功能到"),
              ],
            },
            {
              title: this.$t("lang.地毯底垫"),
              third: [
                this.$t("lang.浴室地垫"),
                this.$t("lang.入户地垫"),
                this.$t("lang.商用地毯"),
                this.$t("lang.床边毯"),
                this.$t("lang.厨房地垫"),
                this.$t("lang.客厅地毯"),
              ],
            },
            {
              title: this.$t("lang.收纳用品"),
              third: [
                this.$t("lang.收纳洞洞板"),
                this.$t("lang.首饰盒"),
                this.$t("lang.夹缝收纳柜"),
                this.$t("lang.化妆品收纳盒"),
                this.$t("lang.搬家纸箱"),
                this.$t("lang.收纳箱"),
                this.$t("lang.鞋盒"),
                this.$t("lang.搬家袋"),
                this.$t("lang.桌面收纳盒"),
                this.$t("lang.内衣收纳盒"),
                this.$t("lang.真空收纳袋"),
                this.$t("lang.旅行收纳袋"),
                this.$t("lang.化妆品收纳包"),
                this.$t("lang.手办收纳盒"),
                this.$t("lang.收纳柜"),
              ],
            },
            {
              title: this.$t("lang.居家布艺"),
              third: [
                this.$t("lang.毛巾浴巾"),
                this.$t("lang.沙发垫"),
                this.$t("lang.门帘"),
                this.$t("lang.一次性洗脸巾"),
                this.$t("lang.飘窗垫"),
                this.$t("lang.干发帽"),
                this.$t("lang.窗纱"),
                this.$t("lang.蒲团"),
                this.$t("lang.毛毯"),
                this.$t("lang.窗帘"),
                this.$t("lang.抱枕靠垫"),
                this.$t("lang.桌布"),
              ],
            },
            {
              title: this.$t("lang.床上用品"),
              third: [
                this.$t("lang.枕头"),
                this.$t("lang.床垫床褥"),
                this.$t("lang.床单床笠"),
                this.$t("lang.蚊帐"),
                this.$t("lang.棉花被"),
                this.$t("lang.四件套"),
                this.$t("lang.纤维被"),
                this.$t("lang.蚕丝被"),
                this.$t("lang.羽绒服被"),
                this.$t("lang.电热毯"),
                this.$t("lang.凉席"),
                this.$t("lang.羊毛被"),
                this.$t("lang.水暖毯"),
                this.$t("lang.凉感被"),
              ],
            },
          ],
        },
        // 家电
        {
          id: "6",
          title: this.$t("lang.家电"),
          showDescription: false,
          img: require("@/assets/img/category6.png"),
          second: [
            {
              title: this.$t("lang.环境电器"),
              third: [
                this.$t("lang.电风扇"),
                this.$t("lang.净水器"),
                this.$t("lang.空气净化器"),
                this.$t("lang.茶吧机"),
                this.$t("lang.除湿机"),
                this.$t("lang.取暖器"),
                this.$t("lang.电暖桌"),
                this.$t("lang.电油灯"),
                this.$t("lang.软水机"),
                this.$t("lang.即热饮水机"),
                this.$t("lang.加热净水器"),
                this.$t("lang.全屋净水"),
                this.$t("lang.加湿净化器"),
                this.$t("lang.无雾加湿器"),
                this.$t("lang.移动地暖"),
              ],
            },
            {
              title: this.$t("lang.空调"),
              third: [
                this.$t("lang.变频空调"),
                this.$t("lang.新风空调"),
                this.$t("lang.新一级能效"),
                this.$t("lang.空调柜机"),
                this.$t("lang.空调挂机"),
                this.$t("lang.中央空调"),
              ],
            },
            {
              title: this.$t("lang.冰箱"),
              third: [
                this.$t("lang.多门冰箱"),
                this.$t("lang.对开门冰箱"),
                this.$t("lang.十字对开门"),
                this.$t("lang.双门冰箱"),
                this.$t("lang.单门冰箱"),
                this.$t("lang.三门冰箱"),
                this.$t("lang.冷柜/冰吧"),
                this.$t("lang.红酒柜"),
              ],
            },
            {
              title: this.$t("lang.洗衣机"),
              third: [
                this.$t("lang.洗烘一体机"),
                this.$t("lang.滚筒洗衣机"),
                this.$t("lang.迷你洗衣机"),
                this.$t("lang.烘干机"),
              ],
            },
            {
              title: this.$t("lang.电视"),
              third: [
                this.$t("lang.K歌电视"),
                this.$t("lang.艺术电视"),
                this.$t("lang.会议电视"),
                this.$t("lang.游戏电视"),
                this.$t("lang.85吋电视"),
                this.$t("lang.98吋电视"),
                this.$t("lang.75吋电视"),
                this.$t("lang.miniled电视"),
                this.$t("lang.电视挂架"),
                this.$t("lang.电视推车"),
                this.$t("lang.电视遥控器"),
              ],
            },
            {
              title: this.$t("lang.厨房小电"),
              third: [
                this.$t("lang.电饭煲"),
                this.$t("lang.电水壶热水瓶"),
                this.$t("lang.电磁炉"),
                this.$t("lang.破壁机"),
                this.$t("lang.电压力锅"),
                this.$t("lang.养生壶"),
                this.$t("lang.电饼档"),
                this.$t("lang.微波炉"),
                this.$t("lang.空气炸锅"),
                this.$t("lang.咖啡机"),
                this.$t("lang.电烤箱"),
                this.$t("lang.三明治早餐机"),
                this.$t("lang.果蔬净化器"),
                this.$t("lang.炒菜机器人"),
                this.$t("lang.电动榨汁杯"),
                this.$t("lang.电煮锅"),
                this.$t("lang.刀筷消毒机"),
                this.$t("lang.电热火锅"),
                this.$t("lang.电蒸锅"),
                this.$t("lang.厨师机"),
                this.$t("lang.绞肉机"),
              ],
            },
            {
              title: this.$t("lang.厨卫大电"),
              third: [
                this.$t("lang.油烟机"),
                this.$t("lang.灶具"),
                this.$t("lang.电热水具"),
                this.$t("lang.燃气热水器"),
                this.$t("lang.消毒柜"),
                this.$t("lang.洗碗机"),
                this.$t("lang.壁挂炉"),
                this.$t("lang.空气能热水器"),
                this.$t("lang.太阳能热水器"),
                this.$t("lang.嵌入式厨电"),
                this.$t("lang.电热水龙头"),
                this.$t("lang.集成水槽"),
                this.$t("lang.集成灶"),
              ],
            },
            {
              title: this.$t("lang.个护健康"),
              third: [
                this.$t("lang.干衣机"),
                this.$t("lang.剃须刀"),
                this.$t("lang.按摩器"),
                this.$t("lang.电吹风"),
                this.$t("lang.电动牙叔"),
                this.$t("lang.理发器"),
                this.$t("lang.美容器"),
                this.$t("lang.卷/直发器"),
                this.$t("lang.冲牙器"),
                this.$t("lang.按摩椅"),
                this.$t("lang.足浴盆"),
                this.$t("lang.剃/脱毛器"),
                this.$t("lang.潮流护理电器"),
                this.$t("lang.洗手机"),
                this.$t("lang.手持挂烫机"),
              ],
            },
            {
              title: this.$t("lang.清洁电器"),
              third: [
                this.$t("lang.扫地机器人"),
                this.$t("lang.蒸汽电动拖把"),
                this.$t("lang.除螨仪"),
                this.$t("lang.擦窗机器人"),
                this.$t("lang.洗地机"),
              ],
            },
            {
              title: this.$t("lang.视听影音"),
              third: [
                this.$t("lang.麦克风"),
                this.$t("lang.KTV音响"),
                this.$t("lang.家庭影响"),
                this.$t("lang.功放"),
                this.$t("lang.迷你音响"),
                this.$t("lang.电视音响"),
              ],
            },
          ],
        },
        // 家居家装
        {
          id: "7",
          title: this.$t("lang.家居家装"),
          showDescription: false,
          img: require("@/assets/img/category7.png"),
          second: [
            {
              title: this.$t("lang.五金工具"),
              third: [
                this.$t("lang.家用五金"),
                this.$t("lang.电钻"),
                this.$t("lang.螺丝刀"),
                this.$t("lang.增压泵"),
                this.$t("lang.水平仪"),
                this.$t("lang.工具套装"),
                this.$t("lang.电锯"),
                this.$t("lang.角磨机"),
                this.$t("lang.测距仪"),
                this.$t("lang.五金工具箱"),
                this.$t("lang.水平尺"),
                this.$t("lang.家用电梯"),
                this.$t("lang.购物车"),
                this.$t("lang.机械锁"),
                this.$t("lang.智能锁"),
              ],
            },
            {
              title: this.$t("lang.电工电料"),
              third: [
                this.$t("lang.开关插座"),
                this.$t("lang.智能家居"),
                this.$t("lang.电料配件"),
                this.$t("lang.智能开关"),
                this.$t("lang.电线/电缆"),
                this.$t("lang.配电箱/断路器"),
                this.$t("lang.电气控制"),
                this.$t("lang.智能窗帘"),
              ],
            },
            {
              title: this.$t("lang.书房家具"),
              third: [
                this.$t("lang.电脑桌"),
                this.$t("lang.电脑椅"),
                this.$t("lang.书架"),
                this.$t("lang.书桌"),
                this.$t("lang.升降桌"),
                this.$t("lang.红木书桌"),
              ],
            },
            {
              title: this.$t("lang.餐厅家具"),
              third: [
                this.$t("lang.岩板餐桌"),
                this.$t("lang.实木餐桌"),
                this.$t("lang.岛台餐桌"),
                this.$t("lang.折叠餐桌"),
                this.$t("lang.餐车"),
                this.$t("lang.餐边柜"),
                this.$t("lang.餐椅"),
                this.$t("lang.红木餐桌"),
                this.$t("lang.红木餐边桌"),
              ],
            },
            {
              title: this.$t("lang.阳台储物"),
              third: [
                this.$t("lang.飘窗桌"),
                this.$t("lang.层架/置物架"),
                this.$t("lang.智能晾衣架"),
                this.$t("lang.阳台柜"),
                this.$t("lang.衣帽架"),
                this.$t("lang.落地晾衣架"),
                this.$t("lang.隐藏晾衣架"),
                this.$t("lang.鞋架"),
                this.$t("lang.储物/收纳"),
                this.$t("lang.手摇晾衣架"),
                this.$t("lang.墙面搁架"),
                this.$t("lang.泳池躺椅"),
              ],
            },
            {
              title: this.$t("lang.厨房卫浴"),
              third: [
                this.$t("lang.厨房配件"),
                this.$t("lang.淋浴花洒"),
                this.$t("lang.龙头"),
                this.$t("lang.厨卫挂件"),
                this.$t("lang.水槽"),
                this.$t("lang.马桶"),
                this.$t("lang.陶瓷件组套"),
                this.$t("lang.浴室柜"),
                this.$t("lang.浴霸"),
                this.$t("lang.净水软水"),
                this.$t("lang.淋浴房"),
                this.$t("lang.智能马桶盖"),
                this.$t("lang.浴缸"),
                this.$t("lang.沐浴桶"),
                this.$t("lang.垃圾处理器"),
              ],
            },
            {
              title: this.$t("lang.客厅家具"),
              third: [
                this.$t("lang.边桌/茶几"),
                this.$t("lang.布艺沙发"),
                this.$t("lang.皮艺沙发"),
                this.$t("lang.鞋柜"),
                this.$t("lang.沙发床"),
                this.$t("lang.电视柜"),
                this.$t("lang.穿衣镜"),
                this.$t("lang.实木沙发"),
                this.$t("lang.屏风"),
                this.$t("lang.懒人沙发"),
                this.$t("lang.功能沙发"),
                this.$t("lang.酒柜"),
                this.$t("lang.红木沙发"),
                this.$t("lang.壁炉"),
                this.$t("lang.红木电视柜"),
              ],
            },
            {
              title: this.$t("lang.卧室家具"),
              third: [
                this.$t("lang.实木床"),
                this.$t("lang.皮艺床"),
                this.$t("lang.衣柜"),
                this.$t("lang.实木衣柜"),
                this.$t("lang.床头柜"),
                this.$t("lang.床垫"),
                this.$t("lang.乳胶床垫"),
                this.$t("lang.弹簧床垫"),
                this.$t("lang.简易衣柜"),
                this.$t("lang.斗柜"),
                this.$t("lang.布艺床"),
                this.$t("lang.床尾凳"),
                this.$t("lang.红木床"),
                this.$t("lang.红木衣柜"),
                this.$t("lang.梳妆台/凳"),
              ],
            },
            {
              title: this.$t("lang.儿童家具"),
              third: [
                this.$t("lang.高低床"),
                this.$t("lang.绘本架"),
                this.$t("lang.儿童床"),
                this.$t("lang.边床"),
                this.$t("lang.儿童桌椅套装"),
                this.$t("lang.儿童椅"),
                this.$t("lang.儿童床垫"),
                this.$t("lang.儿童桌"),
                this.$t("lang.儿童书桌"),
                this.$t("lang.儿童衣柜"),
                this.$t("lang.儿童沙发"),
                this.$t("lang.儿童收纳储物"),
                this.$t("lang.儿童凳"),
                this.$t("lang.儿童玩具桌"),
              ],
            },
            {
              title: this.$t("lang.商业办公"),
              third: [
                this.$t("lang.适老家具"),
                this.$t("lang.餐饮家具"),
                this.$t("lang.会议桌"),
                this.$t("lang.货架"),
                this.$t("lang.办公椅"),
                this.$t("lang.文件柜"),
              ],
            },
            {
              title: this.$t("lang.灯饰照明"),
              third: [
                this.$t("lang.吸顶灯"),
                this.$t("lang.台灯"),
                this.$t("lang.吊灯"),
                this.$t("lang.壁灯"),
                this.$t("lang.镜前灯"),
                this.$t("lang.落地灯"),
                this.$t("lang.氛围照明"),
                this.$t("lang.杀菌灯"),
              ],
            },
          ],
        },
        // 母婴
        {
          id: "8",
          title: this.$t("lang.母婴"),
          showDescription: false,
          img: require("@/assets/img/category8.png"),
          second: [
            {
              title: this.$t("lang.奶粉"),
              third: [
                this.$t("lang.特殊配方奶粉"),
                this.$t("lang.有机奶粉"),
                this.$t("lang.1段"),
                this.$t("lang.2段"),
                this.$t("lang.3段"),
                this.$t("lang.4段"),
                this.$t("lang.羊奶粉"),
                this.$t("lang.孕妈奶粉"),
              ],
            },
            {
              title: this.$t("lang.尿布湿巾"),
              third: [
                this.$t("lang.拉拉裤"),
                this.$t("lang.成人尿裤"),
                this.$t("lang.婴儿湿巾"),
              ],
            },
            {
              title: this.$t("lang.喂养工具"),
              third: [
                this.$t("lang.奶瓶奶嘴"),
                this.$t("lang.水壶/水杯"),
                this.$t("lang.儿童餐具"),
                this.$t("lang.暖奶调奶"),
                this.$t("lang.吸奶器"),
                this.$t("lang.牙胶安抚"),
                this.$t("lang.辅食料理机"),
                this.$t("lang.食物存储"),
                this.$t("lang.消毒锅/柜"),
              ],
            },
            {
              title: this.$t("lang.洗护用品"),
              third: [
                this.$t("lang.宝宝护肤"),
                this.$t("lang.驱蚊防晒"),
                this.$t("lang.洗发沐浴"),
                this.$t("lang.婴儿洗鼻器"),
                this.$t("lang.日常护理"),
                this.$t("lang.口腔清洁"),
                this.$t("lang.洗澡用具"),
                this.$t("lang.棉柔巾"),
                this.$t("lang.洗衣液/皂"),
                this.$t("lang.坐便器"),
                this.$t("lang.奶瓶清洗"),
                this.$t("lang.婴儿理发器"),
              ],
            },
            {
              title: this.$t("lang.营养辅食"),
              third: [
                this.$t("lang.宝宝零食"),
                this.$t("lang.有机辅食"),
                this.$t("lang.米粉/菜粉"),
                this.$t("lang.面条/粥"),
                this.$t("lang.果泥/果汁"),
                this.$t("lang.清火/开胃"),
                this.$t("lang.DHA"),
                this.$t("lang.益生菌/初乳"),
                this.$t("lang.钙铁锌/维生素"),
              ],
            },
          ],
        },
        // 玩具
        {
          id: "9",
          title: this.$t("lang.玩具"),
          showDescription: false,
          img: require("@/assets/img/category9.png"),
          second: [
            {
              title: this.$t("lang.潮流玩具/毛绒"),
              third: [
                this.$t("lang.卡通周边"),
                this.$t("lang.毛绒玩具"),
                this.$t("lang.盲盒"),
                this.$t("lang.网游周边"),
                this.$t("lang.变形模型"),
                this.$t("lang.电影周边"),
                this.$t("lang.人偶/BJD/兵人"),
                this.$t("lang.陀螺"),
                this.$t("lang.靠垫抱枕"),
                this.$t("lang.悠悠球/溜溜球"),
                this.$t("lang.扭蛋"),
              ],
            },
            {
              title: this.$t("lang.创意减压"),
              third: [
                this.$t("lang.减压整蛊"),
                this.$t("lang.魔术道具"),
                this.$t("lang.怀旧玩具"),
                this.$t("lang.转转笔"),
                this.$t("lang.指尖陀螺"),
                this.$t("lang.解锁玩具"),
              ],
            },
            {
              title: this.$t("lang.早教益智"),
              third: [
                this.$t("lang.早教机/故事机"),
                this.$t("lang.积木"),
                this.$t("lang.过家家玩具"),
                this.$t("lang.拼图"),
                this.$t("lang.绘画工具"),
                this.$t("lang.爬行垫/毯"),
                this.$t("lang.魔方"),
                this.$t("lang.桌面玩具"),
                this.$t("lang.STEAM玩具"),
                this.$t("lang.仿真动物玩具"),
                this.$t("lang.摇铃/床铃"),
                this.$t("lang.磁力片"),
                this.$t("lang.健身架"),
                this.$t("lang.敲打玩具"),
                this.$t("lang.点读笔"),
              ],
            },
            {
              title: this.$t("lang.遥控/玩具"),
              third: [
                this.$t("lang.车模"),
                this.$t("lang.软弹枪"),
                this.$t("lang.遥控车"),
                this.$t("lang.遥控飞机"),
                this.$t("lang.轨道车"),
                this.$t("lang.飞机模型"),
                this.$t("lang.遥控船"),
                this.$t("lang.机器人"),
              ],
            },
            {
              title: this.$t("lang.运动划水"),
              third: [
                this.$t("lang.洗澡玩具"),
                this.$t("lang.泡泡枪"),
                this.$t("lang.玩沙玩具"),
                this.$t("lang.电动玩具枪"),
                this.$t("lang.摇马"),
                this.$t("lang.儿童滑梯"),
                this.$t("lang.炫舞毯"),
              ],
            },
            {
              title: this.$t("lang.特色类目"),
              third: [this.$t("lang.手办")],
            },
          ],
        },
        // 文具
        {
          id: "10",
          title: this.$t("lang.文具"),
          showDescription: false,
          img: require("@/assets/img/category10.png"),
          second: [
            {
              title: this.$t("lang.笔类书写工具"),
              third: [
                this.$t("lang.记号笔"),
                this.$t("lang.中性笔"),
                this.$t("lang.钢笔"),
                this.$t("lang.铅笔"),
                this.$t("lang.笔芯/替芯"),
                this.$t("lang.墨囊/墨水"),
                this.$t("lang.荧光笔"),
                this.$t("lang.圆珠笔"),
                this.$t("lang.白板笔"),
                this.$t("lang.宝珠笔"),
                this.$t("lang.针管笔"),
                this.$t("lang.油漆笔"),
                this.$t("lang.秀丽笔"),
              ],
            },
            {
              title: this.$t("lang.本册/印刷品"),
              third: [
                this.$t("lang.笔记本记事本"),
                this.$t("lang.作业本"),
                this.$t("lang.便利贴"),
                this.$t("lang.书签"),
                this.$t("lang.标签贴纸"),
                this.$t("lang.手账"),
                this.$t("lang.文稿纸草稿纸"),
                this.$t("lang.相册"),
                this.$t("lang.信封/红包"),
                this.$t("lang.证书签到册"),
                this.$t("lang.活页替芯"),
                this.$t("lang.贺卡/明信片"),
                this.$t("lang.日程本计划本"),
                this.$t("lang.日历/台历"),
                this.$t("lang.日记本"),
                this.$t("lang.信纸"),
                this.$t("lang.冰箱贴防撞贴"),
                this.$t("lang.宣传单/海报"),
                this.$t("lang.同学录纪念册"),
                this.$t("lang.拍纸本"),
                this.$t("lang.广告纸爆炸贴"),
                this.$t("lang.分页纸索引纸"),
                this.$t("lang.奖状"),
                this.$t("lang.教学用本"),
                this.$t("lang.立体便签拼图"),
                this.$t("lang.电话本通讯录"),
              ],
            },
            {
              title: this.$t("lang.学习用品"),
              third: [
                this.$t("lang.练字帖"),
                this.$t("lang.文具盒/笔袋"),
                this.$t("lang.橡皮擦"),
                this.$t("lang.修正带"),
                this.$t("lang.卷笔刀卷比器"),
                this.$t("lang.贴纸"),
                this.$t("lang.补习袋"),
                this.$t("lang.书皮/书套"),
                this.$t("lang.坐姿修正器"),
                this.$t("lang.阅读器"),
                this.$t("lang.姓名贴"),
                this.$t("lang.握笔器"),
                this.$t("lang.自律打卡器"),
                this.$t("lang.学生计时器"),
                this.$t("lang.学生书桌垫"),
                this.$t("lang.儿童卡通印章"),
                this.$t("lang.修正液"),
                this.$t("lang.笔套/笔帽"),
                this.$t("lang.姓名印章"),
                this.$t("lang.桌面吸尘器"),
                this.$t("lang.答题卡/纸"),
                this.$t("lang.挂书袋"),
                this.$t("lang.红领巾"),
                this.$t("lang.词卡/图卡"),
                this.$t("lang.放大镜"),
                this.$t("lang.拼音机口算机"),
                this.$t("lang.学习护指绷带"),
                this.$t("lang.少先队徽"),
                this.$t("lang.压花器"),
                this.$t("lang.修正贴"),
                this.$t("lang.少先队标志"),
              ],
            },
            {
              title: this.$t("lang.财会用品"),
              third: [this.$t("lang.凭证/单据"), this.$t("lang.印章/印油")],
            },
            {
              title: this.$t("lang.胶粘用品"),
              third: [
                this.$t("lang.透明胶带"),
                this.$t("lang.胶水"),
                this.$t("lang.双面胶带"),
                this.$t("lang.固体胶"),
                this.$t("lang.纳米胶带"),
                this.$t("lang.美纹纸胶带"),
                this.$t("lang.布基胶带"),
                this.$t("lang.点点胶"),
                this.$t("lang.警示胶带"),
                this.$t("lang.绝缘胶带"),
                this.$t("lang.铝箔胶带"),
                this.$t("lang.牛皮纸"),
                this.$t("lang.封箱器"),
                this.$t("lang.学生白胶"),
                this.$t("lang.隐形胶带"),
                this.$t("lang.胶带座"),
                this.$t("lang.浆糊"),
              ],
            },
            {
              title: this.$t("lang.绘图计算用品"),
              third: [
                this.$t("lang.计算器"),
                this.$t("lang.直尺"),
                this.$t("lang.测绘套装"),
                this.$t("lang.圆规"),
                this.$t("lang.皮卷尺/软尺"),
                this.$t("lang.钢卷尺"),
                this.$t("lang.三角尺"),
                this.$t("lang.算盘"),
                this.$t("lang.手抄报模板"),
                this.$t("lang.绘图模板"),
                this.$t("lang.比例尺"),
                this.$t("lang.量角器"),
                this.$t("lang.丁字尺"),
              ],
            },
            {
              title: this.$t("lang.装订用品"),
              third: [
                this.$t("lang.票夹/长尾夹"),
                this.$t("lang.磁扣/磁钉"),
                this.$t("lang.订书机"),
                this.$t("lang.打孔机"),
                this.$t("lang.回形针"),
                this.$t("lang.推夹器"),
                this.$t("lang.别针"),
                this.$t("lang.大头针"),
                this.$t("lang.起钉器"),
                this.$t("lang.图钉"),
                this.$t("lang.三针一钉"),
              ],
            },
            {
              title: this.$t("lang.教学实验用品"),
              third: [
                this.$t("lang.数学教具"),
                this.$t("lang.地球仪"),
                this.$t("lang.教鞭/手指棒"),
                this.$t("lang.粉笔"),
                this.$t("lang.黑板"),
                this.$t("lang.板擦"),
                this.$t("lang.教学模型"),
                this.$t("lang.教学刻章"),
                this.$t("lang.教学标本"),
                this.$t("lang.显微镜"),
              ],
            },
            {
              title: this.$t("lang.手工制作"),
              third: [
                this.$t("lang.彩泥/粘土"),
                this.$t("lang.折纸/DIY"),
                this.$t("lang.咕卡套装"),
                this.$t("lang.火漆印章"),
                this.$t("lang.3D打印笔"),
                this.$t("lang.剪纸/DIY"),
                this.$t("lang.奖励刮刮卡"),
                this.$t("lang.礼品纸"),
                this.$t("lang.纸雕"),
                this.$t("lang.衍纸"),
              ],
            },
          ],
        },
        // 电脑办公
        {
          id: "11",
          title: this.$t("lang.电脑办公"),
          showDescription: false,
          img: require("@/assets/img/category11.png"),
          second: [
            {
              title: this.$t("lang.外设产品"),
              third: [
                this.$t("lang.手机U盘"),
                this.$t("lang.线缆"),
                this.$t("lang.键盘配件"),
                this.$t("lang.平板电脑配件"),
                this.$t("lang.机械键盘"),
                this.$t("lang.插座"),
                this.$t("lang.笔记本配件"),
                this.$t("lang.鼠标"),
                this.$t("lang.鼠标垫"),
                this.$t("lang.U盘"),
                this.$t("lang.扩展坞"),
                this.$t("lang.投屏器"),
                this.$t("lang.移动硬盘"),
                this.$t("lang.移动固态硬盘"),
                this.$t("lang.硬盘盒"),
                this.$t("lang.UPS电源"),
                this.$t("lang.摄像头"),
                this.$t("lang.手写板"),
                this.$t("lang.网络仪表"),
                this.$t("lang.机柜"),
                this.$t("lang.电脑工具"),
                this.$t("lang.电脑清洁"),
              ],
            },
            {
              title: this.$t("lang.电脑整机"),
              third: [
                this.$t("lang.闺蜜机"),
                this.$t("lang.轻薄本"),
                this.$t("lang.二合一平板"),
                this.$t("lang.游戏本"),
                this.$t("lang.商用台式机"),
                this.$t("lang.游戏台式机"),
                this.$t("lang.台式机"),
                this.$t("lang.一体机"),
                this.$t("lang.服务器/工作站"),
              ],
            },
            {
              title: this.$t("lang.耗材"),
              third: [
                this.$t("lang.硒鼓"),
                this.$t("lang.墨盒"),
                this.$t("lang.色带"),
                this.$t("lang.纸类"),
                this.$t("lang.刻录光盘"),
                this.$t("lang.胶带"),
              ],
            },
            {
              title: this.$t("lang.电脑组件"),
              third: [
                this.$t("lang.显示器"),
                this.$t("lang.显示器配件"),
                this.$t("lang.SSD固态硬盘"),
                this.$t("lang.散热器"),
                this.$t("lang.内存"),
                this.$t("lang.显卡"),
                this.$t("lang.机箱"),
                this.$t("lang.组装电脑"),
                this.$t("lang.电源"),
                this.$t("lang.主板"),
                this.$t("lang.硬盘"),
                this.$t("lang.装机配件"),
                this.$t("lang.CPU"),
                this.$t("lang.刻录机/光驱"),
                this.$t("lang.声卡/扩展卡"),
              ],
            },
            {
              title: this.$t("lang.办公设备"),
              third: [
                this.$t("lang.打印机"),
                this.$t("lang.投影机"),
                this.$t("lang.投影配件"),
                this.$t("lang.家用打印机"),
                this.$t("lang.传真设备"),
                this.$t("lang.验钞/点钞机"),
                this.$t("lang.扫描仪"),
                this.$t("lang.条码扫描/采集器"),
                this.$t("lang.复合机"),
                this.$t("lang.碎纸机"),
                this.$t("lang.考勤门禁"),
                this.$t("lang.收银机"),
                this.$t("lang.激光笔"),
                this.$t("lang.保险柜/箱"),
                this.$t("lang.打码机"),
                this.$t("lang.安防监控"),
                this.$t("lang.会议音频视频"),
                this.$t("lang.写字板/支架"),
                this.$t("lang.办公大屏"),
                this.$t("lang.会议平板"),
                this.$t("lang.广告机"),
              ],
            },
            {
              title: this.$t("lang.网络产品"),
              third: [
                this.$t("lang.路由器"),
                this.$t("lang.网线"),
                this.$t("lang.网络配件"),
                this.$t("lang.交换机"),
                this.$t("lang.5G/4G上网"),
                this.$t("lang.网卡"),
                this.$t("lang.网络盒子"),
                this.$t("lang.网络存储"),
              ],
            },
            {
              title: this.$t("lang.游戏设备"),
              third: [
                this.$t("lang.手柄/方向盘"),
                this.$t("lang.游戏软件"),
                this.$t("lang.游戏周边"),
                this.$t("lang.游戏机"),
                this.$t("lang.游戏耳机"),
              ],
            },
          ],
        },
        // 数码
        {
          id: "12",
          title: this.$t("lang.数码"),
          showDescription: false,
          img: require("@/assets/img/category12.png"),
          second: [
            {
              title: this.$t("lang.智能设备"),
              third: [
                this.$t("lang.电话手表"),
                this.$t("lang.XR设备"),
                this.$t("lang.智能戒指"),
                this.$t("lang.监控摄像"),
                this.$t("lang.智能家具"),
                this.$t("lang.智能儿童手表"),
                this.$t("lang.无人机"),
                this.$t("lang.健康监测"),
                this.$t("lang.智能机器人"),
                this.$t("lang.智能配饰"),
                this.$t("lang.智能出行"),
                this.$t("lang.运动跟踪器"),
              ],
            },
            {
              title: this.$t("lang.摄影摄像"),
              third: [
                this.$t("lang.数码相框"),
                this.$t("lang.影棚器材"),
                this.$t("lang.微单相机"),
                this.$t("lang.拍立得"),
                this.$t("lang.数码相机"),
                this.$t("lang.单反相机"),
                this.$t("lang.冲印服务"),
                this.$t("lang.摄像机"),
                this.$t("lang.户外器材"),
              ],
            },
            {
              title: this.$t("lang.电子教育"),
              third: [
                this.$t("lang.学习机"),
                this.$t("lang.电纸书"),
                this.$t("lang.电子词典"),
                this.$t("lang.录音笔"),
                this.$t("lang.点读机/笔"),
                this.$t("lang.复读机"),
                this.$t("lang.翻译机"),
                this.$t("lang.早教益智"),
              ],
            },
          ],
        },
        // 运动户外
        {
          id: "13",
          title: this.$t("lang.运动户外"),
          showDescription: false,
          img: require("@/assets/img/category13.png"),
          second: [
            {
              title: this.$t("lang.骑行运动"),
              third: [
                this.$t("lang.穿戴装备"),
                this.$t("lang.山地车"),
                this.$t("lang.城市自行车"),
                this.$t("lang.折叠车"),
              ],
            },
            {
              title: this.$t("lang.户外装备"),
              third: [
                this.$t("lang.旅游用品"),
                this.$t("lang.户外照明"),
                this.$t("lang.便携桌椅床"),
                this.$t("lang.帐篷/垫子"),
                this.$t("lang.冲浪潜水"),
                this.$t("lang.野餐/烧烤"),
                this.$t("lang.望远镜"),
                this.$t("lang.户外工具"),
                this.$t("lang.军迷用品"),
                this.$t("lang.登山攀岩"),
                this.$t("lang.救援装备"),
                this.$t("lang.睡袋/吊床"),
                this.$t("lang.户外仪表"),
                this.$t("lang.极限户外"),
                this.$t("lang.滑雪装备"),
              ],
            },
            {
              title: this.$t("lang.垂钓用具"),
              third: [
                this.$t("lang.鱼饵"),
                this.$t("lang.钓鱼配件"),
                this.$t("lang.浮漂"),
                this.$t("lang.鱼线"),
                this.$t("lang.鱼线轮"),
                this.$t("lang.钓箱钓椅"),
                this.$t("lang.渔具包"),
                this.$t("lang.钓鱼灯"),
                this.$t("lang.辅助装备"),
                this.$t("lang.钓竿"),
              ],
            },
            {
              title: this.$t("lang.体育用品"),
              third: [
                this.$t("lang.羽毛球拍"),
                this.$t("lang.棋牌麻将"),
                this.$t("lang.篮球"),
                this.$t("lang.乒乓球拍"),
                this.$t("lang.轮滑滑板"),
                this.$t("lang.足球"),
                this.$t("lang.网球拍"),
                this.$t("lang.田径鞋"),
                this.$t("lang.排球"),
                this.$t("lang.台球桌"),
                this.$t("lang.高尔夫球杆套杆"),
                this.$t("lang.棒球"),
              ],
            },
            {
              title: this.$t("lang.健身训练"),
              third: [
                this.$t("lang.哑铃"),
                this.$t("lang.瑜伽用品"),
                this.$t("lang.其他器械"),
                this.$t("lang.武术搏击"),
                this.$t("lang.跑步机"),
                this.$t("lang.动感单车"),
                this.$t("lang.甩脂机"),
                this.$t("lang.综合训练器"),
                this.$t("lang.椭圆机"),
                this.$t("lang.仰卧板/收腹机"),
                this.$t("lang.踏步机"),
              ],
            },
          ],
        },
        // 箱包
        {
          id: "14",
          title: this.$t("lang.箱包"),
          showDescription: false,
          img: require("@/assets/img/category14.png"),
          second: [
            {
              title: this.$t("lang.功能箱包"),
              third: [
                this.$t("lang.旅行配件"),
                this.$t("lang.护脊书包"),
                this.$t("lang.行李箱"),
                this.$t("lang.儿童行李箱"),
                this.$t("lang.儿童书包"),
                this.$t("lang.旅行包"),
              ],
            },
            {
              title: this.$t("lang.潮流女包"),
              third: [
                this.$t("lang.单肩包"),
                this.$t("lang.迷你包"),
                this.$t("lang.手提包"),
                this.$t("lang.女士皮带"),
                this.$t("lang.帆布包"),
                this.$t("lang.斜挎包"),
              ],
            },
            {
              title: this.$t("lang.精品男包"),
              third: [
                this.$t("lang.男士手机包"),
                this.$t("lang.男士皮带"),
                this.$t("lang.单肩斜挎包"),
                this.$t("lang.男士双肩包"),
                this.$t("lang.男士胸包"),
                this.$t("lang.商务公文包"),
              ],
            },
          ],
        },
        // 钟表珠宝
        {
          id: "15",
          title: this.$t("lang.钟表珠宝"),
          showDescription: false,
          img: require("@/assets/img/category15.png"),
          second: [
            {
              title: this.$t("lang.腕表"),
              third: [
                this.$t("lang.国表"),
                this.$t("lang.钟表配件"),
                this.$t("lang.挂钟"),
                this.$t("lang.闹钟"),
                this.$t("lang.智能手表"),
                this.$t("lang.儿童手表"),
              ],
            },
            {
              title: this.$t("lang.饰品"),
              third: [
                this.$t("lang.手链/脚链"),
                this.$t("lang.项链"),
                this.$t("lang.耳饰"),
                this.$t("lang.饰品配件"),
                this.$t("lang.时尚戒指"),
                this.$t("lang.胸针"),
                this.$t("lang.发饰/发卡"),
                this.$t("lang.水晶玛瑙"),
              ],
            },
          ],
        },
        // 奢侈品
        {
          id: "16",
          title: this.$t("lang.奢侈品"),
          showDescription: false,
          img: require("@/assets/img/category16.png"),
          second: [
            {
              title: this.$t("lang.男包"),
              third: [],
            },
            {
              title: this.$t("lang.女包"),
              third: [],
            },
            {
              title: this.$t("lang.饰品"),
              third: [],
            },
          ],
        },
        // 机械设备 运输工具
        {
          id: "17",
          title: this.$t("lang.机械设备"),
          showDescription: false,
          img: require("@/assets/img/category17.png"),
          second: [
            {
              title: this.$t("lang.运输工具"),
              third: [
                this.$t("lang.三轮车"),
                this.$t("lang.货车"),
                this.$t("lang.拖拉机"),
                this.$t("lang.飞行器"),
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
    $(".navbar-collapse a").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });
    $(".mobile-nav ul").hide();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
      $(".mobile-nav ul").toggle("slow");
    },
    handleResize() {
      if (window.innerWidth <= 750) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.pullDownNavTop = this.isMobile ? 0 : 15;
    },
    changeLang(lang, index) {
      this.currentLang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("currentLang", lang);
      window.location.reload();
    },
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    },
    handleScroll(e) {
      this.showLang = false;
      const sections = document.querySelectorAll(".section");
      let currentSection = "";
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 10 && rect.bottom > 0) {
          currentSection = section.id;
        }
      });
      this.activeSection = currentSection;

      this.pullDownNavTop = -120;
      this.showTimer = setTimeout(() => {
        this.pullDownNavTop = this.isMobile ? 0 : 15;
      }, 800);

      if (window.scrollY >= 500) {
        this.fixedBtnRightShow = true;
      } else {
        this.fixedBtnRightShow = true;
      }
      this.navOpen = false;
      $(".mobile-nav ul").hide();
    },
    // 分类
    showDescription(item, index) {
      item.showDescription = true;
      this.categoryIndex = index;
    },
    hideDescription(item) {
      this.categoryIndex = -1;
      item.showDescription = false;
    },
    showFixedBtn() {
      this.fixedBtnRight = "-103px";
      this.fixedBtnWidth = "520px";
      this.fixedBtnZindex = 999;
    },
    hideFixedBtn() {
      this.fixedBtnRight = "-103px";
      this.fixedBtnWidth = "80px";
      this.fixedBtnZindex = 99;
    },
    scrollToTop() {
      window.scrollTo(0, 0); // 滚动到页面的左上角
    },
    handleToggle() {
      this.aboutShowToggle = !this.aboutShowToggle;
      this.aboutToggleText = this.aboutShowToggle
        ? this.$t("lang.收起")
        : this.$t("lang.展开");
    },
    handleMouseEnter() {
      this.aboutToggleImg = require("../assets/img/arrows_white.png");
    },
    handleMouseLeave() {
      this.aboutToggleImg = require("../assets/img/arrows.png");
    },
    openModal(item) {
      this.showModal = true;
      this.modalItem = item;
      // if (this.isMobile) return;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.showModal = false;
      // 恢复背景滚动
      // if (this.isMobile) return;
      document.body.style.overflow = "auto";
    },
  },
};
</script>
<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
}
/* 整体盒子 */
#HomePage {
  width: 100%;
  background: #fff;
}
/* 当导航被点亮后改变颜色 */
.header-nav-wrapper .active {
  color: #847ec3;
}
.btn-style {
  width: max-content;
  background: #c39067;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin: auto;
  height: 80px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #000000;
    transition: all ease 0.6s;
    border: 1px solid #ffffff;
  }
}
.right-icon {
  width: 72px;
  height: 72px;
  margin-left: 22px;
  position: absolute;
  right: 4px;
}
.title {
  font-size: 48px;
  padding-top: 136px;
  font-family: ssstBold;
}
.section {
  transition: all ease 0.6s;
  margin-top: -80px;
}
/* 导航栏 */
.navbar {
  padding: 0 24px;
  height: 82px;
  line-height: 82px;
  border-radius: 16px;
  background: #fff;
  margin: 0 15px;
  width: calc(100% - 30px);
  position: fixed;
  top: 15px;
  z-index: 10;
  transition: all 0.5s;
  z-index: 999;
  .nav {
    padding-top: 31px;
    a {
      padding: 0;
      padding-bottom: 8px;
    }
  }
  .header-nav-logo {
    width: 200px;
    height: 62px;
    float: left;
    margin-top: 10px;
    cursor: pointer;
    img {
      width: 200px;
      height: 62px;
    }
  }
  ul {
    li {
      margin-left: 34px;
      a {
        color: #161c2d;
        font-size: 15px;
        padding-bottom: 8px;
        position: relative;
        cursor: pointer;
      }
      i {
        display: block;
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 0;
        height: 2px;
        opacity: 0;
        transition: all 0.6s ease;
        background-color: #000;
      }
    }
    li > a:focus {
      background: transparent;
    }
    li.active > a {
      font-weight: bold;
      text-decoration: none;
      border-bottom: 2px solid #000000;
      background: transparent;
    }
    li > a:hover .underline {
      opacity: 1;
      width: 100%;
      left: 0;
    }
    li > a:hover {
      text-decoration: none;
      background: transparent;
    }
    .lang {
      width: 56px;
      height: 26px;
      border-radius: 13px;
      border: 1px solid #000000;
      text-align: center;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -3px;
      cursor: pointer;
      p {
        color: #161c2d;
      }
      img {
        margin-left: 7px;
      }
      .active {
        font-weight: bold;
      }
    }
    .lang-pop {
      width: 56px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      position: absolute;
      top: 30px;
      padding: 10px 0;
    }
  }
  .navbar-toggle {
    background: #000;
    margin-top: 24px;
    .icon-bar {
      background: #fff;
    }
  }
}
// 首页
.index {
  transition: all ease 0.6s;
  background: url(../../src/assets/img/bg1g.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  height: 1160px;
  padding-top: 400px;
  color: #fff;
  top: 0;
  .row {
    margin: 0 70px;
  }
  .txt1 {
    font-size: 66px;
    font-weight: bold;
    font-family: ssstBold;
    line-height: 80px;
  }
  .txt2 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    line-height: 30px;
  }
}
.mb20 {
  margin-bottom: 40px;
}
// 公司介绍
.about-us {
  background: url(../../src/assets/img/bg2g.png) no-repeat;
  background-size: 100% 100%;
  // height: 969px;
  color: #161c2d;
  padding-top: 158px;
  padding-bottom: 257px;
  .toggle {
    width: max-content;
    padding: 0 18px;
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    margin-top: 15px;
    cursor: pointer;
    font-weight: bold;
    img {
      width: 12px;
      // height: 12px;
      margin-left: 13px;
    }
    &:hover {
      color: #fff;
      background: #000000;
      transition: all ease 0.6s;
      border: 1px solid #ffffff;
    }
  }
  .lou {
    width: 380px;
    margin: 56px 0;
  }
  .txt1 {
    font-family: ssstBold;
    font-weight: bold;
    font-size: 47px;
    color: #161c2d;
    line-height: 58px;
    width: 100%;
  }
  .txt2 {
    font-weight: 400;
    font-size: 16px;
    color: #161c2d;
    line-height: 32px;
    text-align: left;
    span {
      font-weight: bold;
    }
  }
}
// 分类
.category {
  background: #fff;
  color: #161c2d;
  padding-bottom: 160px;
  .more {
    position: absolute;
    right: 17px;
    bottom: 17px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    color: #c39067;
    img {
      width: 3px !important;
      height: 6px !important;
      margin-left: 3px !important;
    }
  }
  .btn-style {
    padding: 4px 120px 4px 30px;
    margin-top: 30px;
  }
  .title {
    margin-bottom: 83px;
  }
  .item {
    border-radius: 8px;
    margin-bottom: 24px;
    height: 180px;
    cursor: pointer;
    position: relative;
    .box {
      background: linear-gradient(180deg, #f4f7fa 0%, #ffffff 100%);
      width: 100%;
      padding: 24px 24px 36px 24px;
      border-radius: 8px;
      position: relative;
    }
    img {
      width: 80px;
      height: 80px;
      margin: auto;
    }
    .txt1 {
      font-size: 14px;
      margin-top: 24px;
    }
    ul {
      margin-top: 26px;
      line-height: 18px;
      font-size: 12px;
      text-align: center;
      transition: all ease 0.6s;
      opacity: 0.7;
    }
  }
  .bortop {
    border-top: 4px solid #c39067;
    border-radius: 8px;
  }
  .active {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    padding-bottom: 18px !important;
    transition: all ease 0.6s;
    // border-top: 4px solid #c39067;
    .txt1 {
      font-weight: 600;
    }
  }
}

// 服务能力
.service {
  background: url(../../src/assets/img/bg4g.png) no-repeat;
  background-size: 100% 100%;
  // height: 1160px;
  color: #161c2d;
  padding-bottom: 226px;
  .title {
    margin-bottom: 130px;
  }
  .item {
    border-radius: 8px;
    .box {
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(249, 249, 245, 0) 100%
      );
      width: 100%;
      height: 100%;
      border-radius: 8px;
      padding: 50px 30px 46px 30px;
    }

    img {
      width: 32px;
      height: 32px;
      margin: auto;
    }
    .txt1 {
      font-size: 24px;
      margin-top: 22px;
      font-weight: bold;
    }
    .txt2 {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
    }
    ul {
      margin-top: 37px;
      li {
        font-size: 16px;
        line-height: 24px;
        color: #161c2d;
        text-align: left;
        margin-bottom: 24px;
        opacity: 0.7;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          width: 26px;
          height: 2px;
          background: #c39067;
          margin-bottom: 6px;
        }
      }
    }
  }
}

// 反馈
.feedback {
  background: url(../../src/assets/img/bg5g.png) no-repeat;
  background-size: 100% 100%;
  // height: 1160px;
  color: #fff;
  padding-bottom: 266px;
  .icon {
    width: 144px;
    height: 144px;
    margin: 76px auto;
  }
  .txt1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 48px;
    width: 80%;
    margin: auto;
  }
  .btn-style {
    margin-top: 128px;
    padding: 4px 138px 4px 100px;
  }
}
// 合作伙伴
.cooperation {
  // background: url(../../src/assets/img/bg6.png) no-repeat;
  // background-size: 100% 100%;
  background: #fff;
  // height: 1357px;
  padding-bottom: 220px;
  .title {
    margin-bottom: 80px;
  }
  .bracd {
    color: #adadad;
    margin-bottom: 68px;
    p {
      margin-bottom: 48px;
    }
    i {
      width: 8px;
      height: 8px;
      background: #c39067;
      display: block;
      border-radius: 100px;
      margin-right: 12px;
    }
  }
  .img1 {
    width: 783px;
    height: 48px;
  }
  .img2 {
    width: 1201px;
    height: 436px;
  }
  .img3 {
    width: 1200px;
    height: 52px;
  }
}
// 联系我们
.contact-us {
  background: url(../../src/assets/img/bg7g.png) no-repeat;
  background-size: 100% 100%;
  height: 1080px;
  .title {
    margin-bottom: 257px;
  }
  .item {
    padding: 0 40px;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 54px;
      height: 54px;
      margin: auto;
      margin-bottom: 26px;
    }
    p {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.footer {
  height: 100px;
  background: #ffffff;
  .container {
    line-height: 100px;
  }
  .txt1 {
    font-weight: 600;
    font-size: 18px;
    img {
      width: 20px;
      height: 32px;
      margin-right: 10px;
    }
  }
  .txt2 {
    font-weight: 400;
    font-size: 15px;
  }
}
.right-fixed {
  position: fixed;
  bottom: 100px;
  right: 24px;
  cursor: pointer;
  .img1 {
    width: 80px;
    cursor: pointer;
    z-index: 99;
  }
  .img2 {
    width: 54px;
    margin-left: 30px;
  }
  .txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
  .open {
    width: 80px;
    height: 80px;
    background: #c39067;
    border-radius: 40px;
    border: 2px solid #c39067;
    color: #fff;
    margin-right: 24px;
    position: relative;
    transition: all 0.6s ease;
    padding-right: 22px;
    overflow: hidden;
    // z-index: 999;
  }
}
.mobile-nav {
  position: fixed;
  top: 0px;
  width: 100%;
  background: #fff;
  z-index: 99;
  padding: 0 16px;
  transition: all 0.6s ease;
  .top {
    height: 82px;
    cursor: pointer;
  }
  .icon {
    transition: all 0.6s ease;
  }
  ul {
    padding-bottom: 24px;
    li {
      text-align: left;
      line-height: 36px;
      cursor: pointer;
    }
  }
  .lang {
    width: 56px;
    height: 26px;
    border-radius: 13px;
    border: 1px solid #000000;
    margin-top: 24px;
  }
  .lang-pop {
    width: 56px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    position: absolute;
    top: -52px;
    left: 66px;
  }
  .active {
    font-weight: bold;
  }
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 0px;
}

// 分类弹窗
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  // 添加背景遮罩动画
  animation: fadeIn 0.6s ease-in-out;
  .modal-content {
    background: #fff;
    border-radius: 24px;
    width: 1200px;
    padding: 50px 46px;
    // 添加弹框动画
    animation: modalShow 0.6s ease-in-out;
    .content {
      ul {
        max-height: calc(80vh - 400px); // 动态计算最大高度
        overflow: scroll;
        // 添加以下样式来隐藏滚动条
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
      }
    }
  }
  // 添加动画关键帧
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes modalShow {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  .modal-enter {
    opacity: 1;
    transform: scale(1);
  }
  .close-btn {
    cursor: pointer;
    color: #666;
    position: fixed;
    top: 24px;
    right: 24px;
    img {
      width: 36px;
      height: 36px;
    }
  }
  .txt1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 32px;
  }
  .txt2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    margin-top: 24px;
  }
  .txt3 {
    font-size: 12px;
    color: #161c2d;
    line-height: 18px;
    opacity: 0.7;
  }
  .left {
    img {
      width: 200px;
      height: 200px;
    }
  }
  .right {
    text-align: left;
    margin-left: 40px;
  }
}

@media (max-width: 1370px) {
  .navbar {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}
@media (min-width: 1370px) {
  .navbar {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
}
/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  .modal-wrapper {
    .modal-content {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      border-radius: 0;
      padding: 16px 16px 30px 16px;
      // padding: 16px 16px calc(16px + env(safe-area-inset-bottom)); // 修改1: 使用 env() 适配底部安全区域
      .close-btn {
        right: 16px;
      }
      .content {
        ul {
          max-height: 51vh;
          // padding-bottom: env(safe-area-inset-bottom); // 修改2: 内容区域也预留底部安全距离
          li {
            &:first-child {
              .txt2 {
                margin-top: 0px;
              }
            }
          }
        }
      }
      .left {
        img {
          width: 80px;
          height: 80px;
        }
      }
      .right {
        margin-left: 0;
        .txt1 {
          margin-top: 16px;
          margin-bottom: 32px;
        }
      }
    }
  }
  .title {
    font-size: 32px;
    padding-top: 64px;
  }
  .btn-style {
    min-width: auto;
    width: 98%;
    height: 68px;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    padding-left: 16px;
  }
  .section {
    border-radius: 24px;
    margin-top: -32px;
    padding-bottom: 80px;
    height: auto;
  }
  .right-icon {
    width: 60px;
    height: 60px;
  }
  .index {
    height: 813px;
    padding-top: 160px;
    background: url(../../src/assets/img/bg_m1g.png) no-repeat;
    background-size: 100% 100%;
    .txt1 {
      width: auto;
      font-size: 40px;
      line-height: 60px;
    }
    .txt2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 20px;
      width: auto;
    }
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .about-us {
    // height: 1156px;
    padding-top: 64px;
    background: #f4f7fa;
    .lou {
      width: 244px;
      margin: auto;
    }
    .txt1 {
      width: auto;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 48px;
      text-align: center;
    }
    .txt2 {
      width: auto;
      font-size: 14px;
      line-height: 28px;
      margin-top: 32px;
    }
  }
  .category {
    background: #ffffff;
    .row {
      padding: 0 15px;
      .col-xs-6 {
        width: 48%;
      }
    }
    .more {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item {
      height: 203px;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 16px;
      &:nth-child(2n) {
        float: right;
      }
      .box {
      }
      .active {
        padding-bottom: 32px !important;
        .txt1 {
          font-weight: 600;
        }
      }
      ul {
      }
      .txt1 {
        overflow: hidden;
      }
    }
    .btn-style {
      padding: 0 82px 0 16px;
    }
  }
  // 服务能力
  .service {
    // height: 1850px;
    background: #f9f9f5;
    .title {
      margin-bottom: 48px;
    }
  }
  // 反馈
  .feedback {
    // height: 780px;
    background: #111112;
    .icon {
      margin: 48px auto;
    }
    .btn-style {
      margin-top: 32px;
      padding: 0 82px 0 16px;
    }
  }
  .cooperation {
    // height: 1148px;
    background: #ffffff;
    .title {
      margin-bottom: 48px;
    }
    .bracd {
      color: #adadad;
      margin-bottom: 40px;
      p {
        margin-bottom: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // 联系我们
  .contact-us {
    // height: 670px;
    background: #f4f7fa;
    position: relative;
    .bg {
      height: 132px;
      position: absolute;
      top: 160px;
    }
    .title {
      margin-bottom: 50px;
    }
    .item {
      margin-bottom: 36px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer {
    // height: 166px;
    border-radius: 0;
    padding-bottom: 0;
    .container {
      line-height: initial;
    }
    .txt2 {
      margin-top: 16px;
      padding-bottom: 34px;
    }
  }
  .right-fixed {
    bottom: 60px;
    right: 16px;
    .open {
      bottom: 100px;
      width: calc(100vw - 32px);
    }
    .txt {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .navbar {
    margin: 0;
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    .header-nav-logo {
      margin-left: 15px;
    }
    .navbar-collapse {
      background: #fff;
      margin-right: -16px;
      margin-left: -16px;
    }
    .lang {
      margin: auto;
      margin-bottom: 10px;
    }
    ul {
      .lang-pop {
        top: -53px;
        left: 68px;
      }
      li {
        margin-left: 0;
        a {
          padding-bottom: 20px !important;
        }
      }
      li.active > a {
        border-bottom: none;
      }
      li > a:hover .underline {
        opacity: 0;
        width: 100%;
        left: 0;
      }
    }
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
}

@media screen and (max-width: 997px) {
}
// 笔记本屏幕适配
@media screen and (max-height: 1090px) {
  // .modal-wrapper {
  //   .modal-content {
  //     padding: 30px 46px;
  //     .content {
  //       ul {
  //         max-height: calc(75vh - 150px);
  //       }
  //     }
  //   }
  // }
}
</style>
